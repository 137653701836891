export default {
  socialSectionV1: {
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '3rem 1rem',
      textAlign: 'center',
    },
    section: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      marginBottom: '1rem',
      fontSize: '3rem',
    },
    subtitle: {
      marginBottom: '1rem',
    },
    text: { marginBottom: '1rem' },
    iconsContainer: {
      '.socialContainer': {
        flexDirection: 'row',
      },
      a: {
        svg: {
          '&:hover path': {
            fill: 'primary',
          },
        },
      },
      svg: {
        height: '3rem',
        width: '3rem',
        margin: ['0.5rem', '', '3rem 3rem 0'],
      },
      '& > div': {
        height: 'unset',
      },
    },
  },
}
