const variantName = 'teamPage'

export default {
  [variantName]: {
    container: {
      paddingTop: ['100px', '', '', '0px'],
      width: '100%',
      '::before': {
        // this gives the nav a background without having a hero.
        content: ['', '', '', "''"],
        display: 'flex',
        width: '100%',
        height: ['100px', '', '', '140px'],
        backgroundColor: '#231f20',
      },
      display: ['', '', 'flex'],
      flexWrap: 'wrap',
    },

    contentRow: {
      padding: '0rem 1rem',
      width: ['100%', '', '75%'],
      position: 'relative',
    },

    title: {
      fontSize: ['2rem', '2.75rem', '3rem'],
      marginBottom: '0.5rem',
      color: 'primary',
      width: '70%',
    },
    subtitle: {
      marginBottom: '1rem',
      color: 'secondary',
    },
    text: {},

    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      borderBottom: '1px solid',
      opacity: '0.6',
      padding: '0.5rem 0rem 0rem',
      margin: 'auto 0rem 2rem',
      width: '100%',
    },

    readingTime: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
    },

    createdAt: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
    },

    imageContainer: {
      marginBottom: ['', '', '', '1rem'],
      width: ['100%', '', '25%'],
      padding: '1rem',
    },

    image: {
      width: '100%',
    },

    bodyContainer: {
      h1: {
        fontSize: '2rem',
        margin: '0rem 0rem 1rem',
      },
      h2: {
        fontSize: '1.75rem',
        margin: '0rem 0rem 1rem',
      },
      h3: {
        fontSize: '1.5rem',
        margin: '0rem 0rem 1rem',
      },
      h4: {
        fontSize: '1.25rem',
        margin: '0rem 0rem 1rem',
      },
      h5: {
        fontSize: '1rem',
        margin: '0rem 0rem 1rem',
      },
      h6: {
        fontSize: '0.8rem',
        margin: '0rem 0rem 1rem',
      },
      p: {
        lineHeight: '1.5',
        margin: '0rem 0rem 1rem',
        fontWeight: 'normal',
      },
      ul: {
        margin: '0rem 0rem 1rem',
        listStylePosition: 'inside',
      },
      ol: {
        margin: '0rem 0rem 1rem',
        listStylePosition: 'inside',
      },
      li: {
        lineHeight: '1.5',
        marginBottom: '0.5rem',
        fontWeight: 'normal',
      },
    },

    ctaButton: {
      margin: ['1rem 0rem', '', '', '2rem 2rem'],
      position: ['absolute'],
      top: '0rem',
      right: '1rem',
      backgroundColor: 'primary',
      color: 'white',
      padding: '0.75rem',
      borderRadius: '10px',
      width: '25%',
      fontSize: '0.8rem',
      textAlign: 'center',
      maxWidth: '200px',
    },

    // ? ===============================
    // ? ======= Other Articles ========
    // ? ===============================
    otherArticlesContainer: {
      width: ['100%', '', '280px'],
      position: ['', '', 'fixed'],
      left: ['', '', '0rem'],
      bottom: '0rem',
      backgroundColor: 'background',
      padding: ['', '', '1rem 0rem 1rem 1rem'],
      background: ['', '', 'black'],
      borderRadius: ['', '', '0px 20px 0px 0px'],

      ':hover .otherArticles': {
        height: ['', '', '30vh'],
      },
    },
    otherArticleHeading: {
      color: '#ca3625',
      fontSize: ['2rem', '', '1.25rem'],
      marginBottom: ['1rem', '', '0rem'],
      '::after': {
        content: ['', '', "'▼'"],
        color: 'white',
        marginLeft: '0.5rem',
      },
    },
    otherArticles: {
      maxHeight: ['', '', '30vh'],
      overflow: 'scroll',
      transition: 'all ease-in-out 0.6s',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      height: ['', '', '0vh'],
    },
    otherArticle: {
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
      marginBottom: '0.5rem',
      ':hover': {
        h5: {
          textDecoration: 'underline',
        },
      },
    },
    otherArticleImage: {
      width: '60px',
      height: '60px',
      objectFit: 'cover',
    },
    otherArticleContent: {
      padding: '0rem 0.5rem',
      width: '70%',
      display: 'flex',
      justifyContent: ['', '', 'center'],
      flexDirection: 'column',
      backgroundColor: ['', '', 'black'],
    },
    otherArticleTitle: {
      fontSize: '1rem',
      lineHeight: '1.25',
      color: '#00557c',
    },
    otherArticleSubtitle: {
      fontSize: '0.8rem',
      color: ['', '', 'white'],
    },
    otherArticleDate: {
      fontSize: '0.7rem',
      opacity: '0.5',
      display: 'none',
    },
  },
}
