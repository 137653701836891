const variantName = 'contactFormV1a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '3rem 1rem',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      maxWidth: '500px',
    },
    subtitle: {
      marginBottom: 3,
      maxWidth: '500px',
    },
    text: {
      marginBottom: 3,
      maxWidth: '500px',
    },

    // ?============================
    // ?=========  Forms  ==========
    // ?============================

    formContainer: {
      width: '100%',
      maxWidth: '500px',
      flexDirection: 'column',
      // ?== Nested Elements ==

      input: {
        padding: '0.5rem',
        height: '2rem',
        fontSize: '1rem',
        borderRadius: '0px',
        border: 'solid 1px',
        borderColor: 'text',
        // ?== Psuedo Elements ==
        '&::placeholder': {
          color: 'text',
          opacity: '0.8',
          fontSize: '0.9rem',
        },
      },
      textarea: {
        padding: '0.5rem',
        resize: 'vertical',
        borderRadius: '0px',
        fontSize: '1rem',
        border: 'solid 1px',
        borderColor: 'text',
        // ?== Psuedo Elements ==
        '&::placeholder': {
          color: 'text',
          opacity: '0.8',
          fontSize: '0.9rem',
        },
      },
      button: {
        padding: '0.5rem 1rem',
        backgroundColor: 'primary',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        border: 'none',
        borderRadius: '0px',
      },
    },
  },
}
