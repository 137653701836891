const variantName = 'contentSectionV2b'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      whiteSpace: 'break-spaces',
      color: 'light',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
    },
    innerContainer: {
      height: '100vh',
    },
    section: {
      maxWidth: '730px',
      flexDirection: 'column',
      textAlign: ['right', '', ''],
      alignItems: ['flex-end'],
      justifyContent: ['center', '', 'flex-end'],
      padding: '1rem',
      margin: ['0rem 2rem 4rem auto', '', ''],
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      fontSize: ['2.5rem', '', '', '4.5rem'],
      borderRight: 'solid 3px',
      borderColor: 'secondary',
      paddingRight: '1rem',
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal',
    },
    text: {
      zIndex: '100',
      fontSize: ['1rem', '', '', '1.5rem'],
      lineHeight: '1.75',
      color: 'light',
      margin: '0rem 0rem 1rem',
      whiteSpace: 'break-spaces',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '0.75rem 1.25rem',
      backgroundColor: 'secondary',
      width: 'fit-content',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      opacity: 0.9,
      // ?== Psuedo Elements ==
      '&:hover': {
        backgroundColor: 'primary',
      },
    },
  },
}
