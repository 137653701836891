export default {
  sectionNavLinksV2a: {
    container: {
      backgroundColor: 'backgroundSecondary',
      padding: ['', '', '4rem 2rem'],
      '.lazyload-wrapper': {
        width: ['100%', '100%', 'calc(50% - 3rem)', 'calc(50% - 3rem)'],
        margin: 5,
      },
    },
    boxes: {
      maxWidth: '1200px',
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      display: 'flex',
    },
    box: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      height: '300px',
      alignItems: 'stretch',
      flexGrow: '1',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&:hover': {
        backgroundPosition: 'bottom right',
        backgroundSize: 'cover',
      },
      a: {
        width: '100%',
        padding: '1rem 2rem',
        textDecoration: 'none',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover > *': {
          backgroundColor: 'primary',
        },
      },
    },
    linkName: {
      width: '100%',
      color: 'light',
      backgroundColor: 'secondary',
      padding: 4,
      fontSize: 4,
      maxWidth: '250px',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'center',
      bottom: 1,
      right: 1,
      position: 'absolute',
    },
  },
}
