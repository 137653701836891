const variantName = 'ctaWidgetV1b'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      position: 'fixed',
      bottom: ['0px', '', '0.5rem'],
      right: ['', '', '0.5rem'],
      width: ['100%', '', 'unset'],
      border: ['', '', 'none'],
      flexDirection: ['', '', 'column'],
      flexWrap: 'wrap',
      zIndex: '1000',
      color: 'light',

      // ?============================
      // ?=========  Button  =========
      // ?============================

      // TODO: change a to a variantName and give it is own variant rather than nested
      a: {
        backgroundColor: 'primary',
        minWidth: '50%',
        flexGrow: '1',
        padding: ['0.75rem', '', '1rem'],
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: [2, 2, 2, 2],
        transition: 'all ease-in-out 0.5s',
        border: ['solid 1px'],
        borderColor: 'light',
        margin: ['', '', '', '0.25rem'],
        boxShadow: ['', '', '2px 2px 8px black'],

        // ?== Nested Elements ==
        svg: {
          marginRight: ['0.5rem', '', 'unset'],
        },

        // ?== Psuedo Elements ==
        '&:hover': {
          backgroundColor: 'secondary',
        },

        ':last-child': {},
      },
    },
    ctaLabel: {
      display: ['', '', 'none'], // *hides text of label on tablet and just shows the icon
    },
  },
}
