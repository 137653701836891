export default {
  modalV1: {
    container: {},
    modalContainer: {
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: '0px',
      left: '0px',
      zIndex: '2000',
    },
    modalBackground: {
      zIndex: '2000',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    content: {
      zIndex: '2500',
      position: 'absolute',
      top: '50%',
      left: '50%',
      flexDirection: 'column',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      width: '90%',
      maxWidth: '600px',
      maxHeight: '90%',
      minHeight: '200px',
      color: 'text',
      backgroundColor: 'background',
      padding: '1rem',
      borderRadius: '10px',
      border: 'solid 2px white',
      img: {
        maxWidth: '200px',
        margin: '0 auto 2rem',
      },
    },
    modalTitle: {
      marginBottom: '2rem',
    },

    modalText: {
      marginBottom: '1rem',
    },

    modalImages: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalCTALink: {
      margin: '0.5rem',
      display: 'block',
      width: [
        'calc(50% - (0.5rem * 2))',
        'calc(33.333% - (0.5rem * 2))',
        'calc(25% - (0.5rem * 2))',
      ],
    },
    modalImage: {
      width: ['100%'],
      //   margin: '0.5rem',
    },
  },
}
