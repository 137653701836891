const variantName = 'navigationV10b'

export default {
  [variantName]: {
    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '2000',
      boxSizing: 'border-box',
      height: ['75px', '', '', 'auto'],
      padding: ['0.5rem', '', '', '1rem', '2rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'light',
    },
    containerScrolled: {
      variant: `${variantName}.container`,
      transition: 'all ease-in-out 0.5s',
      padding: ['0.5rem', '', '', '0.5rem', '0.5rem'],
      borderBottom: '3px solid',
    },

    hamburger: {
      position: 'relative',
      display: ['flex', '', '', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0rem 0.5rem 0rem auto',
      order: '2',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        background: 'black',
      },
    },
    hamburgerOpen: {
      variant: `${variantName}.hamburger`,
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        background: 'black',
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)',
      },
    },

    navLogo: {
      maxWidth: '200px',
      alignSelf: 'flex-start',
      transition: 'all ease-in-out 0.5s',
    },

    navLogoScrolled: {
      variant: `${variantName}.navLogo`,
      img: {
        maxHeight: '60px',
      },
    },

    menuLogo: {
      maxHeight: ['70px', '', '100px'],
      margin: '1rem 0rem',
      display: ['flex', '', '', 'none'],
      img: {
        height: '100%',
      },
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', '', '', 'static'],
      margin: ['0px', '', '', '0rem 0rem 0rem 1rem'],
      boxSizing: 'border-box',
      justifyContent: 'center',
      height: ['100vh', '', '', 'unset'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '', '', 'auto'],
      minWidth: '250px',
      left: '-150%',
      transition: 'all ease-in-out 0.5s',
      padding: ['1rem 2rem 3rem', '2rem 2rem 3rem', '', '0rem 0rem 0rem 1rem'],
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'center'],
      flexGrow: ['', '', '', '1'],
      overflow: ['hidden', '', '', 'visible'],
      listStyleType: 'none',
      backgroundColor: ['light', '', '', 'transparent'],
      borderLeft: ['10px solid', '', '', '3px solid'],
      borderColor: 'secondary',
    },
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      left: '0%',
    },

    column1: {
      display: 'flex',
      flexDirection: ['column', '', '', 'row'],
      justifyContent: ['', '', '', 'flex-end'],
      margin: ['', '', '1rem', '0rem'],
      width: ['fit-content', '', '40%', '100%'],
      borderRight: ['', '', '2px solid', 'none'],
      borderColor: 'primary',
    },
    column2: {
      display: ['flex', '', '', ''],
      flexDirection: ['column', '', '', 'row'],
      justifyContent: ['', '', '', 'flex-end'],
      width: ['fit-content', '', '40%', 'auto'],
      margin: ['', '', '1rem', '0 0 0 auto'],
      padding: ['0rem', '', '0rem'],
      // borderLeft: ['', '', '2px solid grey'],
      paddingLeft: ['', '', '3rem'],
    },

    smallNavHeading: {
      display: ['', '', '', 'none'],
      fontSize: ['0.9rem', '', '2rem'],
      opacity: '0.75',
      paddingTop: '1rem',
      borderTop: ['2px solid grey', '', 'none'],
      marginBottom: ['0.4rem', '', '1rem'],
      color: 'secondary',
    },

    navItem: {
      margin: ['0.75rem 0rem', '', '', '0.5rem'],
      zIndex: '100',
      listStyleType: 'none',
      a: {
        fontSize: ['1rem', '1.25rem', '1.75rem', '1.25rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        color: 'dark',
        letterSpacing: '2px',
        textDecoration: 'none',
      },
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      backgroundColor: 'primary',
    },

    contactDetailsContainer: {
      display: ['', '', '', 'none'],
      '.text': {
        margin: ['0.25rem 0rem', '', '0.75rem 0rem'],
        fontSize: ['0.8rem', '', '1.5rem'],
        opacity: '0.75',
      },
    },

    socialIconsContainer: {
      marginTop: ['1rem', '', '', '0rem'],
      svg: {
        width: ['20px', '', '', '25px'],
        height: ['20px', '', '', '25px'],
        marginRight: ['0.5rem', '', '', '0.75rem'],
        filter: 'brightness(1) invert(1)',
      },
    },
  },
}
