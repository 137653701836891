const variantName = 'navigationV5'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================

    container: {
      position: ['fixed', 'fixed', 'fixed', 'fixed'],
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      flexDirection: ['', '', '', 'column'],
      height: ['75px', '75px', '75px', '100vh'],
      maxWidth: ['', '', '', '20%'],
      padding: ['0.5rem', '', '0px'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: ['flex-end', '', '', 'flex-start'],
      background: [
        'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%)',
        '',
        '',
        'unset',
      ],
      backgroundColor: ['', '', '', 'primary'],
      boxShadow: ['', '', '', '2px 2px 12px rgba(29, 29, 29, 0.68)'],
    },

    containerScrolled: {
      variant: `${variantName}.container`,
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logo: {
      position: ['absolute', '', '', 'static'],
      top: ['0', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      margin: ['', '', '', '2rem'],
      img: {
        maxHeight: ['101px', '', '', 'unset'],
        maxWidth: ['200px', '', '', '300px'],
        width: ['', '', '', '100%'],
      },
    },

    logoScrolled: {
      variant: `${variantName}.logo`,
      position: ['absolute', '', '', 'static'],
      maxHeight: ['100%', '100px', '', 'unset'],
      margin: ['', '', '', '2rem'],
      textAlign: ['center', '', '', ''],
      left: ['50%', '', '', ''],
      top: ['0px', '', '', '0px'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      height: ['100%', '', '', 'auto'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%', '', '', 'inherit'],
        maxWidth: ['200px', '', '', '300px'],
        width: ['', '', '', '100%'],
        display: 'flex',
        margin: '0 auto',
      },
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px',
      },
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px',
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)',
      },
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', '', '', 'flex-start'],
      height: ['100vh', '100vh', '100vh', '100vh'],
      backgroundColor: ['primary', 'primary', 'primary', 'unset'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['2rem', '', '0.5rem'],
      alignItems: ['center', '', '', 'center'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none',
    },
    navItem: {
      zIndex: '100',
      margin: ['0.75rem', '', '', '0.75rem'],
      listStyleType: 'none',
      a: {
        fontSize: ['1rem', '1.5rem', '2rem', '1.25rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        // color: 'light',
        letterSpacing: '2px',
        textDecoration: 'none',
      },
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      position: ['', '', '', ''],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: '100%',
      li: {
        a: {
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem'],
        },
      },
    },

    // ?==========================
    // ?=====  Nav Widgets  ======
    // ?==========================

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', '', '', 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', '', '', 'unset'],
      zIndex: '2000',
    },

    socialIconsContainer: {
      margin: ['1rem 0rem 0rem', '', '', '0rem 1.5rem'],
      svg: {
        margin: '0.5rem',
      },
    },
    phoneContainer: {
      margin: ['1rem 0rem 0rem', '', '', '0rem 1.5rem'],
    },
  },
}
