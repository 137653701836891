const variantName = 'aboutAdvancedV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    aboutContainers: {
      '> div:nth-of-type(even)': {
        '.content': {
          order: ['', '', '2'],
        },
      },
    },

    sectionContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: ['column', '', 'row'],
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    imageContainer: {
      width: ['100%', '', '50%'],
      padding: '1rem',
      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
      },
      '.slick-slide img': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
      },
    },

    sliderImageFill: { paddingBottom: '100%' },
    sliderImage: {},

    // ?============================
    // ?======  Text Content =======
    // ?============================
    content: {
      display: 'flex',
      padding: ['2rem 1rem', '', '2rem', '3rem 5rem', '3rem 7rem'],
      flexDirection: ['column', '', 'column'],
      width: ['100%', '', '50%'],
      justifyContent: ['', '', 'center'],
    },

    title: {
      marginBottom: 3,
      fontSize: 8,
      fontWeight: '300',
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal',
    },
    body: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '1rem 3rem',
      backgroundColor: 'secondary',
      width: 'fit-content',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      // ?== Psuedo Elements ==
      '&:hover': {
        backgroundColor: 'primary',
      },
    },
  },
}
