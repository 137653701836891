export default {
  sideBySideV1f: {
    container: {
      backgroundColor: 'white',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      '.lazyload-wrapper': {
        position: 'relative',
        width: ['100%', '', '50%'],
        order: ['', '', '1'],
        '::before': {
          content: '""',
          position: 'absolute',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          zIndex: '100',
          background: [
            '',
            '',
            'linear-gradient(90deg,rgb(255 255 255 / 0%),rgb(255 255 255 / 0%) 0%,rgb(255 255 255 / 0%) 50%,rgb(255 255 255 / 10%) 75%,rgb(255 255 255))',
          ],
        },
      },
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '1rem', '2rem'],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      order: ['', '', '2'],
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3,
    },
    title: {
      fontSize: 6,
      marginBottom: 3,
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
      },
    },

    text: {
      lineHeight: '1.75',
      // whiteSpace: 'break-spaces',
    },
    image: {
      position: 'relative',
      width: ['100%'],
      height: '100%',
      objectFit: 'cover',
    },
  },
}
