export default {
  shoutSectionV4: {
    containerWithBlock: {
      position: 'relative',
      display: 'flex',
      flexDirection: ['column', '', 'row'],
    },
    containerWithBackground: {
      width: '100%',
      flexDirection: ['column', 'row'],
      fontSize: [4, 4, 5, 6],
      justifyContent: 'center',
      height: '100vh',
      position: 'relative',
    },

    imageContainerBlock: {
      width: ['100%', '', '50%'],
      img: {
        width: '100%',
        height: '100%',
      },
    },

    contentWithBlock: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', 'flex-start', 'flex-start'],
      width: ['100%', '', '50%'],
      textAlign: ['left', 'left', 'left'],
      padding: ['1rem', '', '2rem'],
    },
    contentWithBackground: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', 'flex-start', 'flex-start'],
      width: ['100%', '50%'],
      flexGrow: '1',
      position: 'absolute',
      top: '50%',
      left: '1rem',
      transform: 'translateY(-50%)',
      color: 'light',
      textAlign: ['left', 'left', 'left'],
      padding: '1rem',
    },
    title: {
      fontSize: ['2.5rem', '', '', '4.5rem'],
      margin: '0rem 0rem 1rem',
      fontWeight: 'bold',
      width: '100%',
      order: '2',
      borderLeft: 'solid 3px',
      borderColor: 'secondary',
      paddingLeft: '1rem',
    },
    text: {
      maxWidth: '500px',
      margin: '0rem 0rem 1rem',
      fontSize: '1em',
      width: '100%',
      order: '3',
    },
    date: {
      fontSize: '0.75em',
      margin: '0rem 0rem 1rem',
      maxWidth: '500px',
      width: '100%',
      order: '1',
    },

    shoutCTAS: {
      flexWrap: 'wrap',
      margin: '0rem',
      transition: 'all ease-in-out 0.3s',
      order: '4',
    },
    shoutCTA: {
      margin: '0rem 0rem 1rem',
      textDecoration: 'underline',
      fontSize: ['1.25rem', '1.5rem'],
      transition: 'all ease-in-out 0.3s',
      ':hover': {
        color: 'primary',
      },
    },
  },
}
