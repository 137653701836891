export default {
  sectionNavLinksV2e: {
    container: {
      '.lazyload-wrapper': {
        width: ['100%', 'calc(50% - 2rem)', '', 'calc(33.33% - 2rem)'],
        margin: '1rem',
      },
    },
    boxes: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      width: '100%',
    },
    box: {
      // width: ['100%', 'calc(50% - 2rem)', '', 'calc(33.33% - 2rem)'],
      width: '100%',
      height: ['60vw', '40vw', '30vw'],
      maxHeight: '360px',
      // flexGrow: '1',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',

      '::after': {
        boxSizing: 'border-box',
        content: '""',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        border: 'solid 4px',
        borderColor: 'primary',
        transition: 'all ease-in-out 0.4s',
        opacity: '0.5',
      },
      ':hover::after': {
        top: '10%',
        left: '10%',
        right: '10%',
        bottom: '10%',
        width: '80%',
        height: '80%',
        backgroundColor: 'rgba(0,0,0,0.75)',
      },
      ':hover .linkName': {
        color: 'primary',
      },
      a: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      },
    },
    linkName: {
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: '20',
      textTransform: 'uppercase',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
