const variantName = 'contentBoxesV1a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '3rem 1rem',
    },

    // ?============================
    // ?=========  Boxes  ==========
    // ?============================

    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto',
    },

    box: {
      display: 'flex',
      flexDirection: 'column',
      width: [
        'calc(100% - 0.5rem * 2)',
        'calc(50% - 0.5rem * 2)',
        'calc(25% - 0.5rem * 2)',
      ],
      margin: '0.5rem',
      overflow: 'hidden',
      border: 'solid 1px',
      borderColor: 'grey',
      borderRadius: '10px',
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      marginBottom: 1,
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    date: {
      marginBottom: 1,
      fontSize: 2,
      paddingX: 2,
      fontWeight: 'bold',
    },
    title: {
      marginBottom: 1,
      paddingX: 2,
    },
    subtitle: {
      marginBottom: 1,
      paddingX: 2,
    },
    text: {
      marginBottom: 1,
      paddingX: 2,
    },
  },
}
