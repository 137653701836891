const variantName = 'navigationV7'

export default {
  [variantName]: {
    container: {
      position: ['fixed', 'fixed', 'fixed', 'fixed'],
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      flexDirection: ['', '', '', 'column'],
      height: ['75px', '75px', '75px', '100vh'],
      maxWidth: ['', '', '', '20%'],
      padding: ['0.5rem', '', '0px'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: ['flex-end', '', '', 'flex-start'],
      background: [
        'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%)',
        '',
        '',
        'unset',
      ],
      backgroundColor: ['', '', '', 'backgroundSecondary'],
      boxShadow: ['', '', '', '2px 2px 12px rgba(29, 29, 29, 0.68)'],
    },

    containerScrolled: {
      variant: `${variantName}.container`,
    },

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', '', '', 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', '', '', 'unset'],
      zIndex: '2000',
    },

    logo: {
      position: ['absolute', '', '', 'static'],
      top: ['0', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      margin: ['', '', '', '2rem'],
      img: {
        marginTop: '1rem',
        maxHeight: ['90px', '', '', 'unset'],
        maxWidth: ['200px', '', '', '300px'],
        width: ['', '', '', '100%'],
      },
    },

    logoScrolled: {
      variant: `${variantName}.logo`,
      position: ['absolute', '', '', 'static'],
      maxHeight: ['100%', '100px', '', 'unset'],
      margin: ['', '', '', '2rem'],
      textAlign: ['center', '', '', ''],
      left: ['50%', '', '', ''],
      top: ['0px', '', '', '0px'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      height: ['100%', '', '', 'auto'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%', '', '', 'unset'],
        maxWidth: ['200px', '', '', '300px'],
        width: ['', '', '', '100%'],
        display: 'flex',
        margin: '0 auto',
        padding: ['0.5rem', '', '', 'unset'],
      },
    },

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px',
      },
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px',
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)',
      },
    },
    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', '', '', 'flex-start'],
      height: ['100vh', '100vh', '100vh', '100vh'],
      backgroundColor: ['backgroundSecondary'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['125px 2rem 1rem', '', '10vh 1rem'],
      alignItems: ['flex-start'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none',
      borderTop: '8px solid',
      borderBottom: '8px solid',
      borderColor: 'primary',
    },
    navItem: {
      zIndex: '100',
      margin: ['0.5rem', '', '', '0.5rem'],
      listStyleType: 'none',
      borderLeft: '6px solid',
      borderColor: 'primary',
      paddingLeft: '0.5rem',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        borderWidth: '25px',
      },
      a: {
        fontSize: ['1.25rem', '1.5rem', '2rem', '1.25rem'],
        textTransform: 'uppercase',
        fontWeight: '700',
        // color: 'light',
        letterSpacing: '2px',
        textDecoration: 'none',
      },
    },
    contactDetailsContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: ['', '', '', '1'],
      '.text': {
        margin: '0.25rem',
      },
    },
    socialIconsContainer: {
      alignItems: 'center',
      justifyContent: ['flex-start', '', '', 'center'],
      flexGrow: ['', '', '', '1'],
      width: '100%',
      margin: ['0.25rem', '1rem', '', '1rem 0rem'],
      svg: {
        width: '25px',
        height: '25px',
        marginRight: '0.25rem',
      },
    },
  },
}
