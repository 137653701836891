const variantName = 'locationMapV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      width: '100%',
      position: 'relative',
      flexDirection: ['column', 'column', 'row'],
      alignItems: 'stretch',
      iframe: {
        width: ['100%', '', '50%'],
        height: 'unset',
        minHeight: '50vh',
      },
      '& div:last-child': {
        paddingBottom: '0px',
        marginBottom: '0px',
      },
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================
    content: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '3rem 1rem',
      textAlign: 'center',
      h3: {
        marginBottom: '2rem',
      },
      width: ['100%', '', '50%'],
      div: {
        justifyContent: 'center',
      },
    },
  },
}
