export default {
  sectionNavLinksV2d: {
    container: {
      padding: ['2rem 1rem', '', '4rem 2rem'],
      '.lazyload-wrapper': {
        width: ['100%', 'calc(50% - 1rem)', '', 'calc(20% - 1rem)'],
        margin: '0.5rem',
        height: '300px',
      },
    },
    boxes: {
      maxWidth: '1200px',
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
      display: 'flex',
      '& > div:nth-of-type(1)': {
        marginTop: ['0rem', '0rem', '', '0rem'],
      },
      '& > div:nth-of-type(2)': {
        marginTop: ['0rem', '6rem', '', '3rem'],
      },
      '& > div:nth-of-type(3)': {
        marginTop: ['0rem', '-6rem', '', '6rem'],
      },
      '& > div:nth-of-type(4)': {
        marginTop: ['0rem', '0rem', '', '9rem'],
      },
      '& > div:nth-of-type(5)': {
        marginTop: ['0rem', '-6rem;', '', '12rem'],
      },
      '& > div:nth-of-type(6)': {
        marginTop: ['0rem', '-6rem;', '', '15rem'],
      },
    },
    box: {
      display: 'flex',
      flexGrow: '1',
      justifyContent: 'center',
      alignItems: 'stretch',
      height: '100%',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&:hover': {
        backgroundPosition: 'bottom left',
      },
      a: {
        width: '100%',
        textDecoration: 'none',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    linkName: {
      color: 'light',
      padding: 4,
      fontSize: 4,
      display: 'inline-block',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'right',
      bottom: 1,
      right: 1,
      position: 'absolute',
    },
  },
}
