export default {
  // text: '#8e5e23',
  // primary: '#dcc600',
  // secondary: '#866f52',
  // background: 'white',
  // backgroundSecondary: '#202020',
  // light: '#FFF',
  // dark: '#020303',
  text: '#232323',
  primary: '#ada89d',
  secondary: '#e7e6e4',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303',
};
