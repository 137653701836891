const variantName = 'navigationV9'

export default {
  [variantName]: {
    container: {
      position: 'fixed',
      width: '100%',
      top: ['0px', '', '', 'unset'],
      bottom: ['', '', '', '0px'],
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: ['90px', '90px', '90px', '75px'],
      padding: ['0.5rem', '', '0px'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'white',
      borderTop: ['', '', '', '2px solid'],
      borderColor: 'primary',
    },

    containerScrolled: {
      variant: `${variantName}.container`,
    },

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', '', '', 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', '', '', 'unset'],
      zIndex: '2000',
    },

    logo: {
      position: ['fixed', '', '', 'fixed'],
      top: ['7.5px', '', '', '1rem'],
      left: ['7.5px', '', '', '1rem'],
      alignSelf: 'top',
      // height: ['100%'],
      zIndex: '2000',
      display: ['inline-block', '', '', ''],
      img: {
        height: ['100%'],
        maxHeight: ['75px'],
      },
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
    },

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px',
      },
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px',
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)',
      },
    },
    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', '', '', 'flex-end'],
      height: ['100vh', '100vh', '100vh', 'unset'],
      backgroundColor: ['secondary', 'secondary', 'secondary', 'unset'],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['3rem 2rem 2rem', '', '', '0.5rem'],
      alignItems: ['flex-start', '', '', 'center'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row'],
      overflow: 'hidden',
      listStyleType: 'none',
      left: '-150%',
    },
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      left: '0%',
    },
    navItem: {
      marginBottom: ['0.75rem', '', '', '0px'],
      zIndex: '100',
      marginLeft: ['0px', '', '0px'],
      margin: ['', '', '', '0.5rem'],
      listStyleType: 'none',
      color: ['light', '', '', 'dark'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        fontSize: ['1.25rem', '1.75rem', '2rem', '1.5rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none',
      },
    },
    socialIconsContainer: {
      margin: ['0.5rem 0rem', '', '', '0rem 0.75rem'],
      svg: {
        marginRight: '0.5rem',
        width: '25px',
        height: '25px',
        path: {
          fill: 'black',
        },
      },
    },
    phoneContainer: {
      borderLeft: ['', '', '', '2px solid #c89495'],
      paddingLeft: ['', '', '', '0.75rem'],
      borderTop: ['4px solid', '', '', 'none'],
      borderColor: 'primary',
      paddingTop: ['0.5rem', '', '', '0rem'],
      margin: ['0.5rem 0rem 0rem', '', '', '0rem 0.75rem'],
      a: {
        // backgroundColor: 'primary',
        padding: '0.25rem 0rem',
        color: ['white', '', '', 'black'],
        fontSize: '0.9rem',
        fontWeight: 'bold',
        letterSpacing: '1px',
        fontFamily: 'Montserrat, sans-serif !important',
        svg: {
          // marginRight: ['0.75rem','','','0rem'],
        },
      },
    },

    addressContainer: {
      a: {
        // backgroundColor: 'primary',
        padding: '0.25rem 0rem',
        color: ['white', '', '', 'black'],
        fontSize: '0.9rem',
        fontWeight: 'bold',
        letterSpacing: '1px',
        fontFamily: 'Montserrat, sans-serif !important',
        svg: {
          // marginRight: ['0.75rem','','','0rem'],
        },
      },
    },

    ctaLabel: {
      display: ['', '', '', 'none'],
      marginLeft: ['0.75rem', '', '', ''],
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      height: 'inherit',
      display: 'flex',
      cursor: 'pointer',
      flexDirection: 'column',
      svg: {
        marginLeft: '0.5rem',
      },
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
      a: {
        fontSize: '0.85rem',
      },
    },

    navItemDropdownMenu: {
      backgroundColor: ['', '', '', 'secondary'],
      position: ['static', '', '', 'absolute'],
      top: '0rem',
      left: '0rem',
      width: '100%',
      height: ['', '', '', '100%'],
      flexDirection: ['column', '', '', 'row'],
      display: 'flex',
      alignItems: ['', '', '', 'center'],
      justifyContent: ['', '', '', 'center'],
      transform: ['', '', '', 'translateY(-100%)'],
      '.navItem': {
        ':hover': {},
      },
    },
  },
}
