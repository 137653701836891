export default {
  sectionNavLinksV2b: {
    container: {
      padding: ['', '', '4rem 2rem'],
    },
    boxes: {
      maxWidth: '1200px',
      margin: '0 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      display: 'flex',
      '& > div:nth-of-type(even)': {
        height: ['', '', '400px'],
      },
      '.lazyload-wrapper': {
        width: [
          '100%',
          'calc(50% - 1rem)',
          'calc(33.33% - 1rem)',
          'calc(20% - 1rem)',
        ],
        margin: '0.5rem',
      },
    },
    box: {
      height: '300px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      flexGrow: '1',
      backgroundPosition: 'center',
      transition: 'all ease-in-out 2s',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&:hover': {
        backgroundPosition: 'bottom left',
      },
      a: {
        width: '100%',
        textDecoration: 'none',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    linkName: {
      color: 'light',
      padding: 4,
      fontSize: 4,
      display: 'inline-block',
      margin: '0 auto',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      textAlign: 'right',
      bottom: 1,
      right: 1,
      position: 'absolute',
    },
  },
}
