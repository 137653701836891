export default {
  pageHeroV1a: {
    container: {
      height: '70vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      zIndex: '1',
      overflow: 'hidden',
    },
    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        marginBottom: 1,
        transformOrigin: 'center',
        transform: 'translate(50%, -50%) rotate(-90deg)',
        top: '50%',
        right: '3.5rem',
        position: 'absolute',
        fontWeight: '300',
        fontSize: ['3rem', '', '4.5rem'],
        width: '100%',
        textTransform: 'uppercase',
      },
      button: {
        variant: 'buttons.multiLocation',
      },
    },
  },
}
