import buttons from '../../../../littlebarn/src/gatsby-theme-minimal/gatsby-plugin-theme-ui/ThemeSettings/buttons'

const variantName = 'locationSwitcherV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      cursor: 'pointer',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    button: {
      variant: 'buttons.primary',
      zIndex: '999',
      padding: '0.5rem 1rem',
    },

    // ?============================
    // ?======  Pop Up Modal =======
    // ?============================

    popUp: {
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: '0px',
      left: '0px',
      zIndex: '2000',
    },
    popUpBackground: {
      zIndex: '2000',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      zIndex: '2500',
      position: 'absolute',
      top: '50%',
      left: '50%',
      flexDirection: 'column',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      width: '90%',
      maxWidth: '600px',
      minHeight: '200px',
      backgroundColor: 'background',
      padding: '1rem',
      borderRadius: '10px',
      border: 'solid 2px white',
      img: {
        maxWidth: '200px',
        margin: '0 auto 2rem',
      },
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxWidth: '200px',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: '2rem',
      color: 'text',
    },

    // ?============================
    // ?=======  Locations  ========
    // ?============================

    locations: {
      textAlign: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      a: {
        variant: 'buttons.primary',
        padding: '0.75rem',
        margin: '0.75rem',
        display: 'flex',
        width: '50%',
        textAlign: 'center',
        justifyContent: 'center',
        maxWidth: '200px',
      },
    },
  },
}
