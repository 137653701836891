const variantName = 'navigationV10'

export default {
  [variantName]: {
    container: {
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: '75px',
      padding: '0.5rem',
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    containerScrolled: {
      variant: `${variantName}.container`,
    },

    hamburger: {
      position: 'relative',
      display: 'flex',
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        background: 'black',
      },
    },
    hamburgerOpen: {
      position: 'relative',
      display: 'flex',
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        background: 'black',
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)',
      },
    },

    navLogo: {
      maxWidth: '200px',
      position: 'absolute',
      top: '0rem',
      padding: '1rem',
      left: '0rem',
      transition: 'all ease-in-out 0.5s',
    },

    navLogoScrolled: {
      variant: `${variantName}.navLogo`,
      backgroundColor: 'black',
      maxWidth: '130px',
    },

    logo: {
      position: ['absolute', '', '', ''],
      top: ['0', '', '', '0px'],
      left: ['50%', '', '', '4'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      alignSelf: 'top',
      zIndex: '2000',
      transition: 'all ease-in-out 0.3s',
      img: {
        maxHeight: '90px',
      },
    },

    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%', '100px'],
      textAlign: ['center', '', '', 'left'],
      left: ['50%', '', '', '4'],
      top: ['0px', '', '', '0px'],
      transform: ['translateX(-50%)', '', '', 'unset'],
      height: ['100%'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%'],
        padding: 0,
      },
    },

    navMenu: {
      position: 'absolute',
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: 'center',
      height: '100vh',
      top: '0px',
      zIndex: '1000',
      width: '100%',
      minWidth: '250px',
      left: '-150%',
      transition: 'all ease-in-out 0.5s',
      padding: ['1rem 2rem 3rem', '2rem 2rem 3rem'],
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'center'],
      overflow: 'hidden',
      listStyleType: 'none',
      backgroundColor: 'light',
    },
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      left: '0%',
    },

    column1: {
      display: 'flex',
      flexDirection: 'column',
      margin: ['', '', '1rem'],
      width: ['fit-content', '', '40%'],
      borderRight: ['', '', '2px solid grey'],
    },
    column2: {
      display: 'flex',
      flexDirection: 'column',
      width: ['fit-content', '', '40%'],
      margin: ['', '', '1rem'],
      padding: ['0rem', '', '0rem'],
      // borderLeft: ['', '', '2px solid grey'],
      paddingLeft: ['', '', '3rem'],
    },

    smallNavHeading: {
      fontSize: ['0.9rem', '', '2rem'],
      opacity: '0.75',
      paddingTop: '1rem',
      borderTop: ['2px solid grey', '', 'none'],
      marginBottom: ['0.4rem', '', '1rem'],
      color: 'secondary',
    },

    menuLogo: {
      maxHeight: ['70px', '', '100px'],
      margin: '1rem 0rem',
      display: 'flex',
      img: {
        height: '100%',
      },
    },
    navItem: {
      margin: '0.75rem 0rem',
      zIndex: '100',
      listStyleType: 'none',
      a: {
        fontSize: ['1rem', '1.25rem', '1.75rem', '2rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        color: 'dark',
        letterSpacing: '2px',
        textDecoration: 'none',
      },
    },

    contactDetailsContainer: {
      '.text': {
        margin: ['0.25rem 0rem', '', '0.75rem 0rem'],
        fontSize: ['0.8rem', '', '1.5rem'],
        opacity: '0.75',
      },
    },

    socialIconsContainer: {
      marginTop: '1rem',
      svg: {
        width: '20px',
        height: '20px',
        marginRight: '0.5rem',
        filter: 'brightness(1) invert(1)',
      },
    },
  },
}
