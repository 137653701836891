const variantName = 'sideBySideV1h'

export default {
  [variantName]: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      '.lazyload-wrapper': {
        width: ['100%', '', '50%'],
        position: 'relative',
        right: ['-20%', '', 'unset'],
      },
      overflow: 'hidden',
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      order: ['2', '', 'unset'],
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      marginBottom: 3,
      fontWeight: 'bold',
    },
    title: {
      fontSize: ['2rem', '2rem', '2.5rem'],
      marginBottom: 3,
      fontWeight: 'normal',
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light',
      },
    },

    text: {
      lineHeight: '1.75',
      // whiteSpace: 'break-spaces',
    },
    image: {
      width: ['100%'],
      height: '100%',
      objectFit: 'cover',
    },
  },
}
