const variantName = 'footerV4a'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      paddingTop: '1rem',
      paddingBottom: '3rem',
      flexDirection: ['column', '', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: ['', '', '', 'center'],
      alignItems: 'center',
      borderTop: '1px solid black',

      // ?== Nested Elements ==
      '.multiButtonContainer': {
        margin: '0rem',
        borderTop: 'solid 1px grey',
        borderBottom: 'solid 1px grey',
        width: '100%',
        a: {
          color: 'text',
          margin: '0px',
          border: 'none',
          padding: ['1.5rem 2.5rem', '', '', '1.5rem 3.5rem'],
          borderTop: ['1px grey solid', '', 'none'],
          borderBottom: ['1px grey solid', '', 'none'],
          borderRight: '1px grey solid',
          flexGrow: ['1', '', 'unset'],
          textAlign: 'center',
          textTransform: 'capitalize',
          justifyContent: 'center',
          fontSize: '0.8rem',
          fontWeight: '300',
          ':hover': {
            color: 'light',
          },
        },

        // ?== Psuedo Elements ==
        '& a:nth-of-type(1)': {
          borderLeft: ['', '', '1px grey solid'],
        },
      },

      '.contactDetails-container': {
        textAlign: ['center', '', 'left'],
        '.text': {
          fontSize: '0.9rem',
          ':hover': {
            color: 'primary',
            opacity: '0.8',
          },
        },
      },

      '.gonationLogo': {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        svg: {
          height: '1.25rem',
          width: 'auto',
        },
      },
      '.socialContainer': {
        margin: ['1rem auto', '', '', '1rem 0.75rem'],
        svg: {
          marginRight: '0.5rem',
          width: '18px',
          height: '18px',
          ':hover': {
            opacity: '0.6',
          },
          path: {
            fill: 'dark',
          },
        },
      },
      '.copyright': {
        padding: '1rem',
        fontWeight: 'bold',
        backgroundColor: 'primary',
        color: 'light',
      },

      // ?== Psuedo Elements ==
      '& > div:nth-of-type(1)': {
        // column1
        borderRight: ['', '', '', '1px solid grey'],
        alignItems: 'flex-end',
        padding: '1rem 1rem',
      },
      '& > div:nth-of-type(2)': {
        // column2
        alignItems: 'flex-start',
        padding: '1rem 1rem',
      },
    },

    column: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'center'],
      width: ['', '', '', '50%'],
      textAlign: 'left',
      marginBottom: ['', '', '', '1rem'],
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: ['100px', '', '130px'],
    },

    // ?============================
    // ?======  Bottom Bar  ========
    // ?============================

    bottomBar: {
      width: '100%',
      flexDirection: ['column', '', '', 'row'],
      alignItems: 'center',
      justifyContent: ['center', '', '', 'space-between'],
      marginTop: ['1rem'],
      paddingTop: '1rem',
      margin: ['1rem 0rem 0rem', '', '', '1rem 2.5rem'],
      maxWidth: '767px',
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    quote: {
      width: ['', '', '', '50%'],
      margin: '1rem auto',
      fontSize: '2rem',
      fontStyle: 'italic',
      maxWidth: '600px',
      textAlign: ['center', '', '', 'left'],
      padding: '1rem',
    },

    poweredBy: {
      margin: '1rem 0rem 0rem',
      '.gonationLogo': {
        filter: 'brightness(0)',
      },
    },
    poweredByText: {
      fontSize: '1.25rem',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },

    // ! Considering Removing Pop Contents Feature and using this in shadowing only
    popUpContentBoxesContainer: {
      margin: '0.5rem',
      '& > button': {
        padding: '1',
        fontSize: '0.8rem',
      },
    },
  },
}
