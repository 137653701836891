const variantName = 'contentSectionV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      whiteSpace: 'break-spaces',
      color: 'light',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
    },
    innerContainer: {
      height: '100vh',
    },
    section: {
      maxWidth: '767px',
      margin: '0 auto',
      flexDirection: 'column',
      textAlign: ['', '', 'center'],
      alignItems: ['center'],
      justifyContent: 'center',
    },
    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      fontSize: 8,
    },
    subtitle: {
      marginBottom: 3,
      fontSize: 4,
      fontWeight: 'normal',
    },
    text: {
      zIndex: '100',
      fontSize: [3, 3, 3, 3],
      lineHeight: '1.75',
      color: 'light',
      marginY: 3,
      whiteSpace: 'break-spaces',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================
    ctaButton: {
      display: 'flex',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '1rem 3rem',
      backgroundColor: 'secondary',
      width: 'fit-content',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      opacity: 0.9,
      // ?== Psuedo Elements ==
      '&:hover': {
        backgroundColor: 'primary',
      },
    },
  },
}
