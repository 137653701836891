export default {
  sideBySideV4a: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', 'column', 'row'],
      alignItems: ['', '', 'stretch'],
      padding: '2rem 0',
    },
    content: {
      flexDirection: 'column',
      order: ['', '', '2'],
      padding: ['1rem', '', '1.5rem', '3rem'],
      width: ['100%', '100%', '50%', '40%'],
      justifyContent: 'center',
      textAlign: 'left',
      alignItems: 'flex-start',
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 8,
      fontSize: 5,
      borderBottom: '3px solid',
    },
    title: {
      fontSize: 8,
      marginBottom: 5,
      lineHeight: '1.4',
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light',
      },
    },

    text: {
      lineHeight: '1.75',
      whiteSpace: 'break-spaces',
    },

    imageContainer: {
      width: ['100%', '100%', '50%', '60%'],
      order: ['', '', '1'],
      maxHeight: '75vh',

      '.lazyload-wrapper': {
        display: 'flex',
        width: '100%',
      },
      '.image1': { height: '100%' },
      '.image2': { height: '50%' },
      '.image3': { height: '50%' },
    },
    imageColumn1: {
      flexDirection: 'column',
      width: 'calc(50% - 0.5rem)',
    },
    imageColumn2: {
      flexDirection: 'column',
      width: 'calc(50% - 0.5rem)',
    },
    image: {
      width: ['100%', '100%', '100%'],
      margin: ['0.5rem', '', 'unset'],
      objectFit: 'cover',
      padding: '0.5rem',
    },
  },
}
