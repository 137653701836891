const variantName = 'aboutSectionV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
      position: 'relative',
      backgroundColor: 'background',
      whiteSpace: 'break-spaces',
      color: 'text',
      alignItems: 'stretch',
    },

    section: {
      width: ['100%', '100%', '50%'],
      display: 'flex',
      alignItems: ['center', 'center', 'flex-start'],
      flexDirection: 'column',
      justifyContent: 'center',
      padding: ['2rem 1rem', '2rem 2rem', '3rem'],
      fontSize: ['0.85rem', '0.85rem', '0.85rem', '0.95rem'],
      textAlign: ['center', 'center', 'left'],
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      width: ['100%', '100%', '50%'],
      objectFit: 'cover',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: 6,
      width: '100%',
      marginBottom: 4,
      textAlign: ['center', 'center', 'left'],
    },
    subtitle: {
      marginBottom: 4,
      fontSize: 4,
      fontWeight: 'normal',
    },
    text: {
      whiteSpace: 'break-spaces',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================
    ctaButton: {
      marginTop: '1.5rem',
      textAlign: 'center',
      padding: '1rem 3rem',
      backgroundColor: 'secondary',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      transition: 'all ease-in-out 0.5s',
      // ?== Psuedo Elements ==
      ':hover': {
        backgroundColor: 'primary',
      },
    },
  },
}
