const variantName = 'heroV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {},
    hero: {
      height: '100vh',
      width: '100%',
      display: 'block',
      position: 'relative',

      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '100vh',
      },
      '.slick-slide img': {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
      },
      '.slick-dots': {
        bottom: '25px',
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px',
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px',
      },
      '.slick-next': {
        right: '5px',
        zIndex: '500',
      },
      '.slick-prev': {
        left: '0px',
        zIndex: '500',
      },

      'svg.slick-arrow': {
        width: '20px',
        color: 'primary',
      },
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      position: 'absolute',
      top: '50%',
      left: '0%',
      transform: 'translate(0%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      padding: '2rem',
      zIndex: '100',
      boxSizing: 'border-box',
      maxWidth: '767px',
      marginLeft: ['', '', '', '2rem'],
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================
    logoHero: {
      maxHeight: '250px',
      margin: '0 auto 1rem',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: ['1.8rem', '2rem', '2.5rem', '3rem'],
      marginBottom: 3,
      whiteSpace: 'pre-wrap',
    },
    subtitle: {
      fontSize: ['1.3rem', '1.5rem', '1.8rem'],
      fontWeight: 'normal',
      marginBottom: 3,
      whiteSpace: 'pre-wrap',
    },
    text: {
      fontSize: ['1rem', '', '1.25rem'],
      whiteSpace: 'pre-wrap',
      color: 'light',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      margin: '1rem 0',
      padding: '0.75rem 1.5rem',
      backgroundColor: 'primary',
      width: 'fit-content',
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    // the widget has the same variants as the hero so we can't not pass down a variant name
    shoutWidgetContainer: {
      // ?== Nested Elements ==
      '.shoutWidget': {
        width: '100%',
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        padding: '0rem',
        '.content': {
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: '1',
          paddingLeft: 2,
          paddingRight: 1,
          paddingY: 1,
          width: ['65%', '', '50%'],
          color: 'text',
        },
        '.title': {
          fontSize: 3,
          fontWeight: 'bold',
          color: 'secondary',
        },
        '.text': {
          fontSize: '0.8rem',
          marginY: 1,
        },
        '.date': {
          fontSize: 2,
          color: 'secondary',
          fontWeight: 'bold',
        },
        '.imageContainer': {
          width: ['35%', '', '50%'],
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
        '.shoutCTAS': {
          flexWrap: 'wrap',
          marginBottom: '0.5rem',
          a: {
            margin: '0.5rem 1rem 0.5rem 0rem',
            textDecoration: 'underline',
            fontSize: ['1.25rem', '1.5rem'],
            ':hover': {
              color: 'primary',
            },
          },
        },
      },
    },
  },
}
