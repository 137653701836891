// ?============================================
// ?============  Theme Imports  ===============
// ?============================================

import colors from './ThemeSettings/colors';
import fonts from './ThemeSettings/fonts';
import fontWeights from './ThemeSettings/fontWeights';
import lineHeights from './ThemeSettings/lineHeights';
import buttons from './ThemeSettings/buttons';
import text from './ThemeSettings/text';
import breakpoints from './ThemeSettings/breakpoints';
import fontSizes from './ThemeSettings/fontSizes';
import space from './ThemeSettings/space';
import styles from './ThemeSettings/styles';
import bodyHTMLStyles from './ThemeSettings/bodyHTMLStyles';
import customVariants from './ThemeSettings/customVariants';

// ?============================================
// ?=====  Component Style Sheet Imports  ======
// ?============================================

import notFound from './404/notFound';

import openingPageV1 from './OpeningPage/openingPageV1';

import navigationV1 from './Navigation/navigationV1';
import navigationV1a from './Navigation/navigationV1a';
import navigationV1b from './Navigation/navigationV1b';
import navigationV1c from './Navigation/navigationV1c';
import navigationV2 from './Navigation/navigationV2';
import navigationV2a from './Navigation/navigationV2a';
import navigationV3 from './Navigation/navigationV3';
import navigationV4 from './Navigation/navigationV4';
import navigationV4a from './Navigation/navigationV4a';
import navigationV4b from './Navigation/navigationV4b';
import navigationV4c from './Navigation/navigationV4c';
import navigationV5 from './Navigation/navigationV5';
import navigationV6 from './Navigation/navigationV6';
import navigationV7 from './Navigation/navigationV7';
import navigationV8 from './Navigation/navigationV8';
import navigationV9 from './Navigation/navigationV9';
import navigationV10 from './Navigation/navigationV10';
import navigationV10a from './Navigation/navigationV10a';
import navigationV10b from './Navigation/navigationV10b';
import navigationV11 from './Navigation/navigationV11';

import navItemDropdownV1 from './Navigation/dropdown/navItemDropdownV1';

import footerV1 from './Footer/footerV1';
import footerV2 from './Footer/footerV2';
import footerV3 from './Footer/footerV3';
import footerV4 from './Footer/footerV4';
import footerV4a from './Footer/footerV4a';
import footerV5 from './Footer/footerV5';
import footerV6 from './Footer/footerV6';

import ctaWidgetV1 from './CTAWidget/ctaWidgetV1';
import ctaWidgetV1a from './CTAWidget/ctaWidgetV1a';
import ctaWidgetV1b from './CTAWidget/ctaWidgetV1b';

import multiLocationV1 from './MultiLocations/multiLocationsV1';
import multiLocationV2 from './MultiLocations/multiLocationsV2';
import multiLocationsV3 from './MultiLocations/multiLocationsV3';
import multiLocationsV4 from './MultiLocations/multiLocationsV4';
import multiLocationsV5 from './MultiLocations/multiLocationsV5';

import teamMenu from './Article/teamMenu';
import teamPage from './Article/teamPage';
import pressMenu from './Article/pressMenu';
import pressPage from './Article/pressPage';
import generalMenu from './Article/generalMenu';
import generalPage from './Article/generalPage';
import policiesMenu from './Article/policiesMenu';
import policiesPage from './Article/policiesPage';
import blogMenu from './Article/blogMenu';
import blogPage from './Article/blogPage';

import heroV1 from './Heros/heroV1';
import heroV2 from './Heros/heroV2';
import heroV2a from './Heros/heroV2a';
import heroV2b from './Heros/heroV2b';
import heroV2c from './Heros/heroV2c';
import heroV2d from './Heros/heroV2d';
import heroV2e from './Heros/heroV2e';
import heroV2f from './Heros/heroV2f';
import heroV2g from './Heros/heroV2g';
import heroV3 from './Heros/heroV3';
import heroV3a from './Heros/heroV3a';
import heroV4 from './Heros/heroV4';
import heroV5 from './Heros/heroV5';
import heroV5a from './Heros/heroV5a';
import heroV5b from './Heros/heroV5b';
import heroV6 from './Heros/heroV6';
import heroV7 from './Heros/heroV7';
import heroV9 from './Heros/heroV9';

import pageHeroV1 from './PageHero/pageHeroV1';
import pageHeroV1a from './PageHero/pageHeroV1a';
import pageHeroV2 from './PageHero/pageHeroV2';

import eventsV1 from './Events/eventsV1';
import eventsV1a from './Events/eventsV1a';
import eventsV2 from './Events/eventsV2';

import menuV1 from './Menu/menuV1';
import menuV1a from './Menu/menuV1a';
import menuV1b from './Menu/menuV1b';
import menuV1c from './Menu/menuV1c';
import menuV1d from './Menu/menuV1d';
import menuV2 from './Menu/menuV2';
import menuV3 from './Menu/menuV3';
import menuV3a from './Menu/menuV3a';
import menuV3b from './Menu/menuV3b';
import menuV4 from './Menu/menuV4';
import menuV5 from './Menu/menuV5';

import galleryV1 from './Gallery/galleryV1';
import galleryV1a from './Gallery/galleryV1a';
import galleryV1b from './Gallery/galleryV1b';
import galleryV2 from './Gallery/galleryV2';
import galleryV3 from './Gallery/galleryV3';
import galleryV3a from './Gallery/galleryV3a';
import galleryV4 from './Gallery/galleryV4';
import galleryV4a from './Gallery/galleryV4a';
import galleryV5 from './Gallery/galleryV5';
import galleryV6 from './Gallery/galleryV6';

import shoutSectionV1 from './ShoutSection/shoutSectionV1';
import shoutSectionV2 from './ShoutSection/shoutSectionV2';
import shoutSectionV2a from './ShoutSection/shoutSectionV2a';
import shoutSectionV2b from './ShoutSection/shoutSectionV2b';
import shoutSectionV2c from './ShoutSection/shoutSectionV2c';
import shoutSectionV3 from './ShoutSection/shoutSectionV3';
import shoutSectionV4 from './ShoutSection/shoutSectionV4';
import shoutSectionV5 from './ShoutSection/shoutSectionV5';

import aboutSectionV1 from './AboutSection/aboutSectionV1';
import aboutSectionV2 from './AboutSection/aboutSectionV2';

import aboutAdvancedV1 from './AboutAdvanced/aboutAdvancedV1';

import contentSectionV1 from './ContentSection/contentSectionV1';
import contentSectionV1a from './ContentSection/contentSectionV1a';
import contentSectionV2 from './ContentSection/contentSectionV2';
import contentSectionV2a from './ContentSection/contentSectionV2a';
import contentSectionV2b from './ContentSection/contentSectionV2b';

import sectionNavLinksV1 from './SectionNavLinks/sectionNavLinksV1';
import sectionNavLinksV2 from './SectionNavLinks/sectionNavLinksV2';
import sectionNavLinksV2a from './SectionNavLinks/sectionNavLinksV2a';
import sectionNavLinksV2b from './SectionNavLinks/sectionNavLinksV2b';
import sectionNavLinksV2c from './SectionNavLinks/sectionNavLinksV2c';
import sectionNavLinksV2d from './SectionNavLinks/sectionNavLinksV2d';
import sectionNavLinksV2e from './SectionNavLinks/sectionNavLinksV2e';
import sectionNavLinksV2f from './SectionNavLinks/sectionNavLinksV2f';

import contactFormV1 from './ContactForms/contactFormV1';
import contactFormV1a from './ContactForms/contactFormV1a';
import contactFormV2 from './ContactForms/contactFormV2';

import shoutWidgetV1 from './ShoutWidgets/shoutWidgetV1';

import hoursV1 from './Hours/hoursV1';
import hoursV1a from './Hours/hoursV1a';

import titleBlockV1 from './TitleBlock/titleBlockV1';
import titleBlockV1a from './TitleBlock/titleBlockV1a';
import titleBlockV1b from './TitleBlock/titleBlockV1b';

import contentBoxesV1 from './ContentBoxes/contentBoxesV1';
import contentBoxesV1a from './ContentBoxes/contentBoxesV1a';
import contentBoxesV2 from './ContentBoxes/contentBoxesV2';
import contentBoxesV3 from './ContentBoxes/contentBoxesV3';

import sideBySideV1 from './SideBySide/SideBySideV1';
import sideBySideV1a from './SideBySide/SideBySideV1a';
import sideBySideV1b from './SideBySide/SideBySideV1b';
import sideBySideV1c from './SideBySide/SideBySideV1c';
import sideBySideV1d from './SideBySide/SideBySideV1d';
import sideBySideV1e from './SideBySide/SideBySideV1e';
import sideBySideV1f from './SideBySide/SideBySideV1f';
import sideBySideV1g from './SideBySide/SideBySideV1g';
import sideBySideV1h from './SideBySide/SideBySideV1h';
import sideBySideV2 from './SideBySide/SideBySideV2';
import sideBySideV2a from './SideBySide/SideBySideV2a';
import sideBySideV2b from './SideBySide/SideBySideV2b';
import sideBySideV3 from './SideBySide/SideBySideV3';
import sideBySideV4 from './SideBySide/SideBySideV4';
import sideBySideV4a from './SideBySide/SideBySideV4a';
import sideBySideV5 from './SideBySide/SideBySideV5';
import sideBySideV6 from './SideBySide/SideBySideV6';

import locationMapV1 from './LocationMap/locationMapV1';
import locationMapV2 from './LocationMap/locationMapV2';

import locationBoxesV1 from './LocationBoxes/locationBoxesV1';
import locationBoxesV2 from './LocationBoxes/locationBoxesV2';
import locationBoxesV3 from './LocationBoxes/locationBoxesV3';

import socialIconsV1 from './General/SocialIcons/socialIconsV1';
import socialIconsV2 from './General/SocialIcons/socialIconsV2';

import socialSectionV1 from './SocialSection/socialSectionV1';

import locationSwitcherSectionV1 from './LocationSwitcherSection/locationSwitcherSectionV1';

import locationSwitcherV1 from './LocationSwitcher/locationSwitcherV1';

import modalV1 from './Modal/modalV1';

import sliderV1 from './Sliders/sliderV1';

import instagramWidgetV1 from './InstagramWidget/instagramWidgetV1';

import instagramV1 from './Instagram/instagramV1';

import scrollProgressBarV1 from './General/ScrollProgressBar/ScrollProgressBarV1';

// == general imports ==

import copyright from './General/copyright';
import contactDetails from './General/contactDetails';
import spinnerContainer from './General/spinnerContainer';

import multiButtonsV1 from './General/MultiButtons/multiButtonsV1';
import multiButtonsV2 from './General/MultiButtons/multiButtonsV2';

// ?============================================
// ?=============  Theme Object ================
// ?============================================

export default {
  initialColorMode: `default`, // not sure what this does
  // ======== base styles ========
  colors: {
    ...colors,
    // modes: {
    //   ...businessThemes,
    //   // default: { backgroundColor: 'background' },
    // },
  },
  fontSizes: fontSizes,
  space: space,
  breakpoints: breakpoints,
  fonts: {
    ...fonts,
  },
  fontWeights: {
    ...fontWeights,
  },
  lineHeights: {
    ...lineHeights,
  },
  text: {
    ...text,
  },
  styles: {
    ...styles,
  },
  buttons: {
    ...buttons,
  },
  bodyHTMLStyles: {
    ...bodyHTMLStyles,
  },

  widget: {
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '300px',
    },
  },

  // ?============================================================
  // ?=================== Custom Styles ==========================
  // ?============================================================

  // ?===============================
  // ?====== Multi Locations ========
  // ?===============================

  ...multiLocationV1,
  ...multiLocationV2,
  ...multiLocationsV3,
  ...multiLocationsV4,
  ...multiLocationsV5,

  // ?===============================
  // ?======= Opening Pages =========
  // ?===============================

  ...openingPageV1,

  // ?===============================
  // ?======= 404 Page =========
  // ?===============================

  ...notFound,

  // ?===============================
  // ?======= Articles Page =========
  // ?===============================

  ...teamMenu,
  ...teamPage,
  ...pressMenu,
  ...pressPage,
  ...generalMenu,
  ...generalPage,
  ...policiesMenu,
  ...policiesPage,
  ...blogMenu,
  ...blogPage,

  // ?============================================================
  // ?======================= Layout =============================
  // ?============================================================

  // ?================================
  // ?========= Navigation ===========
  // ?================================

  ...navigationV1,
  ...navigationV1a,
  ...navigationV1b,
  ...navigationV1c,
  ...navigationV2,
  ...navigationV2a,
  ...navigationV3,
  ...navigationV4,
  ...navigationV4a,
  ...navigationV4b,
  ...navigationV4c,
  ...navigationV5,
  ...navigationV6,
  ...navigationV7,
  ...navigationV8,
  ...navigationV9,
  ...navigationV10,
  ...navigationV10a,
  ...navigationV10b,
  ...navigationV11,

  // ?================================
  // ?===== Navigation Dropdown ======
  // ?================================

  ...navItemDropdownV1,

  // ?================================
  // ?=========== Footer =============
  // ?================================

  ...footerV1,
  ...footerV2,
  ...footerV3,
  ...footerV4,
  ...footerV4a,
  ...footerV5,
  ...footerV6,

  // ?================================
  // ?======== CTA WIDGETS ===========
  // ?================================

  ...ctaWidgetV1,
  ...ctaWidgetV1a,
  ...ctaWidgetV1b,

  // ?===============================
  // ?======= HomePage heros ========
  // ?===============================

  ...heroV1,
  ...heroV2,
  ...heroV2a,
  ...heroV2b,
  ...heroV2c,
  ...heroV2d,
  ...heroV2e,
  ...heroV2f,
  ...heroV2g,
  ...heroV3,
  ...heroV3a,
  ...heroV4,
  ...heroV5,
  ...heroV5a,
  ...heroV5b,
  ...heroV6,
  ...heroV7,
  ...heroV9,

  // ?===========================
  // ?======= page heros ========
  // ?===========================

  ...pageHeroV1,
  ...pageHeroV1a,
  ...pageHeroV2,

  // ?==============================
  // ?=========== SHOUTS ===========
  // ?==============================

  ...shoutSectionV1,
  ...shoutSectionV2,
  ...shoutSectionV2a,
  ...shoutSectionV2b,
  ...shoutSectionV2c,
  ...shoutSectionV3,
  ...shoutSectionV4,
  ...shoutSectionV5,

  // ?==============================
  // ?======== SHOUT Widgets ========
  // ?==============================

  ...shoutWidgetV1,

  // ?==============================
  // ?=========== ABOUTS ===========
  // ?==============================

  ...aboutSectionV1,
  ...aboutSectionV2,

  // ?==============================
  // ?======= ABOUT ADVANCED =======
  // ?==============================

  ...aboutAdvancedV1,

  // ?==============================
  // ?=========== ABOUTS ===========
  // ?==============================

  ...contentSectionV1,
  ...contentSectionV1a,
  ...contentSectionV2,
  ...contentSectionV2a,
  ...contentSectionV2b,

  // ?================================
  // ?=====  Section Nav Links  ======
  // ?================================

  ...sectionNavLinksV1,
  ...sectionNavLinksV2,
  ...sectionNavLinksV2a,
  ...sectionNavLinksV2b,
  ...sectionNavLinksV2c,
  ...sectionNavLinksV2d,
  ...sectionNavLinksV2e,
  ...sectionNavLinksV2f,

  // ?================================
  // ?============ Menus =============
  // ?================================

  ...menuV1,
  ...menuV1a,
  ...menuV1b,
  ...menuV1c,
  ...menuV1d,
  ...menuV2,
  ...menuV3,
  ...menuV3a,
  ...menuV3b,
  ...menuV4,
  ...menuV5,

  // ?================================
  // ?=========== Gallery ============
  // ?================================

  ...galleryV1,
  ...galleryV1a,
  ...galleryV1b,
  ...galleryV2,
  ...galleryV3,
  ...galleryV3a,
  ...galleryV4,
  ...galleryV4a,
  ...galleryV5,
  ...galleryV6,

  // ?================================
  // ?========= Events Page ==========
  // ?================================

  ...eventsV1,
  ...eventsV1a,
  ...eventsV2,

  // ?================================
  // ?======== Gallery Page ==========
  // ?================================

  ...galleryV1,

  // ?================================
  // ?======== Contact Form ==========
  // ?================================

  ...contactFormV1,
  ...contactFormV1a,
  ...contactFormV2,

  // ?================================
  // ?=========== Hours  ==-==========
  // ?================================

  ...hoursV1,
  ...hoursV1a,

  // ?================================
  // ?======== Title Blocks  =========
  // ?================================

  ...titleBlockV1,
  ...titleBlockV1a,
  ...titleBlockV1b,

  // ?================================
  // ?======== Side By Side ==========
  // ?================================

  ...sideBySideV1,
  ...sideBySideV1a,
  ...sideBySideV1b,
  ...sideBySideV1c,
  ...sideBySideV1d,
  ...sideBySideV1e,
  ...sideBySideV1f,
  ...sideBySideV1g,
  ...sideBySideV1h,
  ...sideBySideV2,
  ...sideBySideV2a,
  ...sideBySideV2b,
  ...sideBySideV3,
  ...sideBySideV4,
  ...sideBySideV4a,
  ...sideBySideV5,
  ...sideBySideV6,

  // ?================================
  // ?======== LocationBoxes ==========
  // ?================================

  ...locationBoxesV1,
  ...locationBoxesV2,
  ...locationBoxesV3,

  // ?================================
  // ?======= Social Section =========
  // ?================================

  ...socialSectionV1,

  // ?================================
  // ?=== Location Switcher Section ==
  // ?================================

  ...locationSwitcherSectionV1,

  // ?============================================================
  // ?================== General Components ======================
  // ?============================================================

  ...spinnerContainer,
  ...contactDetails,
  ...copyright,

  // ?=================================
  // ?======== Social Icons ===========
  // ?=================================

  ...socialIconsV1,
  ...socialIconsV2,

  // ?=================================
  // ?======== Content Boxes ==========
  // ?=================================

  ...contentBoxesV1,
  ...contentBoxesV1a,
  ...contentBoxesV2,
  ...contentBoxesV3,

  // ?=================================
  // ?======== Location Map ===========
  // ?=================================

  ...locationMapV1,
  ...locationMapV2,

  // ?=================================
  // ?====== Location Switcher ========
  // ?=================================

  ...locationSwitcherV1,

  // ?=================================
  // ?=========== Modals ==============
  // ?=================================

  ...modalV1,

  // ?=================================
  // ?========== Sliders ==============
  // ?=================================

  ...sliderV1,

  // ?=================================
  // ?===== Instagram Widget ==========
  // ?=================================

  // ? lightwidget built
  ...instagramWidgetV1,

  // ? === Custom Built
  ...instagramV1,

  // ?=================================
  // ?======== Content Boxes ==========
  // ?=================================
  ...multiButtonsV1,
  ...multiButtonsV2,

  // ?=================================
  // ?===== Scroll Progress Bar =======
  // ?=================================

  ...scrollProgressBarV1,

  // ?=================================
  // ?======= Custom Variants =========
  // ?=================================
  // !  Leave This At The Bottom
  customVariants: {
    ...customVariants,
  },
};
