const variantName = 'socialIconsV2'

export default {
  [variantName]: {
    container: {
      flexDirection: 'column',
      display: 'flex',
      height: '25px',
      a: {
        height: 'inherit',
        width: 'inherit',
      },
      svg: {
        marginRight: 2,
        height: 'inherit',
        width: 'inherit',
      },
      path: {
        fill: 'inherit',
      },
    },
  },
}
