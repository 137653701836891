const variantName = 'scrollProgressBarV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    progressContainer: {
      position: 'fixed',
      top: '0px',
      width: '100%',
      zIndex: '6000',
    },
    progressBarContainer: {
      width: '100%',
      height: '3px',
      background: 'rgb(241 241 241 / 51%)',
    },

    // ?============================
    // ?==========  Bar  ===========
    // ?============================

    progressBar: {
      height: '3px',
      backgroundColor: 'secondary',
      width: '0%',
    },
  },
}
