const variantName = 'navigationV4b'

export default {
  [variantName]: {
    container: {
      // ?============================
      // ?======== Container =========
      // ?============================
      position: 'fixed',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: ['', '', '', '100vh'],
      padding: ['0.5rem', '', '', '2rem 1rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      color: 'light',
      '.seperatorLine': {
        display: 'none',
        margin: '1rem auto 1rem',
        width: '90%',
        maxWidth: '200px',
      },
    },
    containerScrolled: {
      variant: `${variantName}.container`,
      height: ['75px', '75px', '75px', 'unset'],
      padding: ['0.5rem', '', '', '2rem 1rem'],
      background: 'unset',
      backgroundColor: ['background', '', '', 'unset'],
      color: 'white',
      '.hamburger div': {
        // backgroundColor:''
        filter: ['invert(1)', '', '', 'unset'],
      },
      '.smallNavMenu ': {
        display: 'none',
        right: '-100vw',
        transition: 'all ease-in-out 0.5s',
      },
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logoLocationContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0rem',
      height: '100%',
      position: ['', '', '', 'absolute'],
      left: ['', '', '', '50%'],
      transform: ['', '', '', 'translateX(-50%)'],
      textAlign: 'center',
      display: ['inline-block', '', '', ''],
      margin: ['0 auto 0 0', '', '', 'auto'],
    },

    locationSwitcher: {
      backgroundColor: '',
      zIndex: '2000',
      alignSelf: 'flex-start',
      button: {
        backgroundColor: 'unset',
        borderTop: '1px solid',
        borderRadius: '0px',
      },
      '.popUp': {
        transform: ['', '', '', 'translateX(-45%)'],
      },
      '.popUp .content': {
        backgroundColor: 'rgb(255 255 255 / 74%)',
      },
    },

    logo: {
      marginBottom: '0rem',
      zIndex: '2000',
      transition: 'all ease-in-out 0.4s',
      justifyContent: 'center',
      img: {
        maxHeight: ['100px', '', '150px', '200px'],
      },
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%'],
      height: ['100%'],
      display: ['inline-block', '', '', 'inline'],
      textAlign: 'left',
      img: {
        height: ['100%'],
        maxHeight: ['100%', '100px'],
        padding: 0,
      },
    },

    navMenuLogo: {
      img: {
        maxHeight: ['150px', '', '150px', '200px'],
        border: 'solid 1px black',
      },
      marginBottom: '1rem',
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', ''],
      zIndex: '2600',
      alignSelf: ['center', '', '', 'flex-start'],
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      minWidth: '30px',
      margin: '0.75rem',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'white',
      },
    },
    hamburgerOpen: {
      variant: `${variantName}.hamburger`,
      height: 'unset',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'primary',
        borderRadius: '10px',
      },
      '& > div:nth-of-type(1)': {
        transform: 'rotate(135deg) ',
      },
      '& > div:nth-of-type(2)': {
        transform: 'rotate(-135deg)',
      },
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', 'center', 'center'],

      backgroundColor: ['background'],

      zIndex: '2500',
      width: ['100%', '100%', '100%', '100%'],
      height: '100vh',
      minWidth: '250px',
      top: '-100vh',

      right: '0px',
      transition: 'all ease-in-out 1s',
      padding: ['1rem', '', '', '4rem'],
      alignItems: ['flex-start', 'flex-start', 'flex-start'],
      textAlign: 'center',
      display: 'flex',
      flexDirection: ['column', 'column', 'column'],
      overflow: 'hidden',
      listStyleType: 'none',
      color: 'dark',
    },

    // when nav is open it uses this variant
    navMenuOpen: {
      variant: `${variantName}.navMenu`,
      right: '0%',
      top: '0%',
      height: '100vh',
    },

    navItem: {
      margin: ['0.35rem', '0.75rem', '', '0.5rem'],
      zIndex: '100',
      listStyleType: 'none',
      textAlign: 'left',
      a: {
        fontSize: ['1.2rem', '', '', '1.5rem', '2rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        letterSpacing: '2px',
        textDecoration: 'none',
        ':hover': {
          color: 'secondary',
        },
      },
    },

    smallNavMenu: {
      display: ['none', '', '', 'flex'],
      position: ['', '', '', 'absolute'],
      flexDirection: 'column',
      right: '1.5rem',
      bottom: '8rem',
      textAlign: 'right',
      transition: 'all ease-in-out 0.5s',
      li: {
        a: {
          variant: 'buttons.primary',
          fontSize: '1rem',
        },
      },
    },

    smallNavHeading: {
      fontSize: '1rem',
      marginBottom: '0.5rem',
      fontWeight: '400',
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative',
      cursor: 'pointer',
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap',
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: 'primary',
    },

    // ?==========================
    // ?=====  Nav Widgets  ======
    // ?==========================

    phoneContainerDesktop: {
      display: ['none', '', '', 'block'],
      borderRight: 'solid 1px',
      borderColor: 'secondary',
      padding: '0.75rem 0.75rem 0.75rem 0rem',
      a: {
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          color: 'secondary',
          fontSize: '1.1rem',
        },
      },
    },

    socialIconsContainerDesktop: {
      display: ['none', '', '', 'flex'],
      padding: '0.5rem 1rem',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: ['', '', '', '0 auto 0 0'],
      a: {
        display: 'flex',
        textAlign: 'center',
        padding: '0.5rem',
        alignItems: 'center',
        transition: 'all ease-in-out 0.5s',
        ':hover': {
          transform: 'scale(1.5)',
          svg: {
            path: { fill: 'secondary' },
          },
        },
      },
    },

    phoneSocialContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      padding: ['', '', '', '3rem'],
      border: ['', '', '', 'solid 1px black'],
      justifyContent: 'center',
      width: ['100%', '', '', 'fit-content'],
      maxWidth: '450px',
      margin: '1rem 0rem',
      position: ['', '', '', 'absolute'],
      right: ['', '', '', '25%'],
      // transform: ['', '', '', 'translateX(-50%)'],
    },

    phoneContainer: {
      padding: '0.25rem',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      marginBottom: '1rem',
      a: {
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: '0.8rem',
        textAlign: 'left',
        justifyContent: 'flex-start',
        svg: {
          marginRight: '0.25rem',
        },
      },
    },

    socialIconsContainer: {
      padding: '0.25rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      textAlign: 'left',
      a: {
        display: 'flex',
        textAlign: 'left',
        padding: '0.5rem',
        alignItems: 'flex-start',
        svg: {
          path: { fill: 'text' },
        },
      },
    },

    // ?==========================
    // ?=====  Nav Block  ======
    // ?==========================

    navBlock: {
      display: 'flex',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      position: 'absolute',
      margin: '0rem',
      zIndex: '500',
      transition: 'all ease-in-out 0.5s',
      top: '0rem',
      right: '-200%',
      width: ['100vw', '100vw', '100vw', '100vw'],
      height: ['100vh', '100vh', '100vh', '100vh'],
    },
    navBlockOpen: {
      variant: `${variantName}.navBlock`,
      right: '0rem',
      top: '0rem',
    },
  },
}
