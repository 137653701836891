const variantName = 'heroV2f'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {},
    hero: {
      width: '100%',
      display: 'block',
      position: 'relative',
      overflow: 'hidden',
      margin: '2rem 0rem',

      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-slider': {
        position: ['', '', 'relative'],
        left: ['', '', '10%'],
        width: ['', '', '90%'],
      },

      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: ['50vh', '', '50vh'],
      },
      '.slick-slide img': {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        filter: 'brightness(0.8)',
      },
      '.slick-dots': {
        bottom: '25px',
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px',
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px',
      },

      '.slick-next': {
        top: 'unset',
        bottom: '0rem',
        right: '0rem',
        zIndex: '500',
        width: '20px',
        color: 'black',
        backgroundColor: 'white',
      },
      '.slick-prev': {
        left: 'unset',
        right: '3rem',
        zIndex: '500',
        width: '20px',
        color: 'white',
        backgroundColor: 'black',
      },
      '.slick-arrow': {
        top: 'unset',
        bottom: '0rem',
        padding: '0.25rem',
        height: '2.5rem',
        width: '3rem',
        ':hover': {
          background: 'inherit',
          color: 'inherit',
          opacity: '0.6',
        },
      },
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      position: ['', '', 'relative'],
      left: ['', '', '40%'],
      width: ['100%', '', '60%'],
      maxWidth: '767px',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: ['1rem', '', '', '2rem 2rem'],
      zIndex: '100',
      boxSizing: 'border-box',
      margin: ['1rem 0rem', '', '', '2rem 1rem 3rem'],
      color: 'text',

      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoHero: {
      maxHeight: '250px',
      margin: '0 auto 1rem',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: 3,
      fontSize: ['4rem', '', '', ''],
      position: ['', '', 'absolute'],
      left: ['', '', '-60%'],
      top: ['', '', '0rem'],
      width: ['100%', '', 'fit-content'],
      maxWidth: ['', '', '250px', '350px'],
      borderRight: ['', '', 'solid 2px'],
      borderColor: 'primary',
      whiteSpace: 'pre-wrap',
    },
    subtitle: {
      fontSize: ['1.5rem', '', '2.5rem'],
      fontWeight: 'normal',
      marginBottom: 3,
      whiteSpace: 'pre-wrap',
    },
    text: {
      maxWidth: ['', '', '600px'],
      whiteSpace: 'pre-wrap',
      color: 'text',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      margin: '1rem 0',
      padding: '0.75rem 1.5rem',
      backgroundColor: 'primary',
      width: 'fit-content',
      color: 'light',
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'secondary',
        color: 'black',
      },
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    // the widget has the same variants as the hero so we can't not pass down a variant name
    shoutWidgetContainer: {
      display: 'none',
      '.shoutWidget': {
        width: '100%',
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        padding: '0rem',
        '.content': {
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: '1',
          paddingLeft: 2,
          paddingRight: 1,
          paddingY: 1,
          width: ['65%', '', '50%'],
          color: 'text',
        },
        '.title': {
          fontSize: 3,
          fontWeight: 'bold',
          color: 'secondary',
        },
        '.text': {
          fontSize: '0.8rem',
          marginY: 1,
        },
        '.date': {
          fontSize: 2,
          color: 'secondary',
          fontWeight: 'bold',
        },
        '.imageContainer': {
          width: ['35%', '', '50%'],
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
        '.shoutCTAS': {
          flexWrap: 'wrap',
          marginBottom: '0.5rem',
          a: {
            margin: '0.5rem 1rem 0.5rem 0rem',
            textDecoration: 'underline',
            fontSize: ['1.25rem', '1.5rem'],
            ':hover': {
              color: 'primary',
            },
          },
        },
      },
    },
  },
}
