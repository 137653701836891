const variantName = 'heroV4'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      width: '100%',
      flexDirection: ['column', '', 'row'],
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      width: ['100%', '', '60%'],
      height: ['40vh', '', '70vh'],
      objectFit: 'cover',
      order: ['', '', '2'],
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      width: ['100%', '', '40%'],
      padding: '2rem',
      flexDirection: 'column',
      textAlign: ['center', '', 'left'],
      justifyContent: ['center'],
      alignItems: ['center', '', 'flex-start'],
      maxWidth: ['', '', '350px'],
      margin: ['', '', '0 1rem 0 auto'],
      order: ['', '', '1'],
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      marginBottom: 4,
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: ['2rem', '', '1.8rem', '2rem'],
      marginTop: '0px',
      textTransform: 'Uppercase',
      marginBottom: '1rem',
      //   maxWidth: '350px',
      //   margin: '0 0 0 auto',
    },
    subtitle: {
      fontSize: '1rem',
      marginTop: '0px',
      marginBottom: 4,
      opacity: '0.8',
    },

    text: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      variant: 'buttons.multiLocation',
      width: 'fit-content',
      display: 'flex',
      marginTop: '1rem',
      color: 'black',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '0.75rem 1.5rem',
      borderTop: 'solid 2px',
      borderBottom: 'solid 2px',
      borderColor: 'black',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        backgroundColor: 'secondary',
        color: 'white',
        borderColor: 'white',
      },
    },
  },
}
