export default {
  primary: {
    backgroundColor: '#FFFDD0',
    color: 'dark',
    border: 'solid 2px',
    borderColor: '#FFFDD0',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    transition: 'all ease-in-out 0.5s',

    ':hover': {
      backgroundColor: 'primary',
      borderColor: 'white',
    },
  },
  secondary: {
    backgroundColor: '#202020',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'white',
    },
  },
};
