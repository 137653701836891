const variantName = 'instagramV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      flexDirection: ['row', '', 'row'],
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        height: '300px',
        width: '300px',
      },
      img: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
        padding: '0.5rem',
        filter: ['', '', '', 'brightness(0.85)'],
        transition: 'all ease-in-out 0.3s',
        ':hover': {
          filter: 'brightness(1)',
        },
      },
      '.slick-arrow': {
        width: '30px',
        height: '30px',
        zIndex: '1',
        color: 'primary',
      },

      '.slick-prev': {
        left: '0rem',
      },

      '.slick-next': {
        right: '0rem',
      },
    },

    header: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      svg: {
        width: '50px',
        height: '50px',
        marginRight: '1rem',
      },
    },
  },
}
