const variantName = 'galleryV4'

export default {
  [variantName]: {
    container: {
      width: '100%',

      // slick styles
      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-slider': {
        height: '30vh',
        width: '100%',
      },

      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '30vh',
      },
      '.slick-slide img': {
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '0rem',
        objectFit: 'cover',
        width: '100%',
        transform: 'translate(-50%, -50%)',
      },
      '.slick-dots': {
        bottom: '-25px',
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px',
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px',
      },
      '.slick-next, .slick-prev': {
        zIndex: '500',
        width: '30px',
        height: '30px',
        fontSize: '1rem',
      },

      '.slick-next': {
        right: '5px',
      },
      '.slick-prev': {
        left: '0px',
      },
      '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': {
        opacity: '0.7',
        color: 'inherit',
      },
      '.slick-arrow': {
        color: 'primary',
      },
    },

    wrapper: {
      width: '100%',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    textContent: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1rem',
      textAlign: 'center',
    },

    // ?============================
    // ?====  Each Slide Style =====
    // ?============================

    slideBox: {
      flexDirection: 'column',
      display: 'flex !important', // slider package gives it a inline style of inline block
      margin: '0.5rem',
      width: 'calc(100% - 1rem)!important',
    },
    imageContainer: {
      position: 'relative',
    },
    imageFill: {
      paddingBottom: '100%',
    },

    // ?============================
    // ?===  Slide Text Content ====
    // ?============================
    title: { marginBottom: '0.5rem', fontSize: '1.5rem' },
    subtitle: { marginBottom: '0.5rem', fontSize: '1.1rem', opacity: '0.8' },
    text: { marginBottom: '0.5rem', fontSize: '1rem' },
  },
}
