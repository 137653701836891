const variantName = 'footerV3'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      paddingX: [4, '', '', 10],
      paddingY: 8,
      backgroundColor: 'primary',
      flexDirection: ['column', '', '', 'row'],
      flexWrap: 'wrap',
      alignItems: 'center',
      maxWidth: '1200px',
      margin: '0 auto',
      color: 'light',
      // ?== Nested Elements ==
      '.gonationLogo': {
        filter: 'brightness(0) invert(1)',
        flexDirection: 'column',
      },

      '.socialIconsContainer svg': {
        width: '25px',
        height: '25px',
        marginRight: '0.5rem',
      },
    },

    column: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['center', '', '', 'flex-start'],
      width: ['', '', '', '50%'],
      textAlign: 'left',
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logo: {
      maxHeight: '150px',
    },

    quote: {
      width: ['', '', '', '50%'],
      margin: '1rem auto',
      fontSize: '2rem',
      fontStyle: 'italic',
      maxWidth: '600px',
      textAlign: ['center', '', '', 'left'],
      padding: '1rem',
    },

    // ?============================
    // ?======  Bottom Bar  ========
    // ?============================

    bottomBar: {
      width: '100%',
      flexDirection: ['column', '', '', 'row'],
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: ['', '', '', '1px solid'],
      borderColor: ['', '', '', 'inherit'],
      marginTop: ['', '', '', '2rem'],
      // ?== Nested Elements ==
      '> div': {
        marginBottom: '1rem',
        margin: '0.5rem',
      },
      '.multiButtonContainer ': {
        flexGrow: '1',
        justifyContent: ['', '', '', 'flex-start'],
        a: {
          border: 'none',
          margin: '0.5rem',
          color: 'light',
          // ?== Psuedo Elements ==
          ':hover': {
            opacity: '0.8',
          },
        },
        width: 'unset',
      },
      // ?== Psuedo Elements ==
      '> div:last-child': {
        width: ['', '', '', 'unset'],
        flexGrow: '1',
      },
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    // ! Considering Removing Pop Contents Feature and using this in shadowing only
    popUpContentBoxesContainer: {
      margin: '1rem 0rem',
      // ?== Psuedo Elements ==
      '& > button': {
        padding: '1',
        fontSize: '0.8rem',
      },
    },
  },
}
