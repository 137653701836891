const variantName = 'heroV2c'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {},
    hero: {
      height: '100vh',
      width: '100%',
      display: 'block',
      position: 'relative',

      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '100vh',
      },
      '.slick-slide img': {
        height: '100vh',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        filter: ['brightness(0.8)', '', '', 'unset'],
      },
      '.slick-dots': {
        bottom: '25px',
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px',
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px',
      },
      '.slick-next': {
        right: '5px',
        zIndex: '500',
      },
      '.slick-prev': {
        left: '0px',
        zIndex: '500',
      },
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      position: 'absolute',
      textAlign: 'left',
      height: '100%',
      justifyContent: ['center', '', '', 'flex-end'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: ['1rem', '2rem', '', '2rem 2rem 10vh'],
      zIndex: '100',
      boxSizing: 'border-box',
      maxWidth: '767px',
      clipPath: ['', '', '', 'polygon(0 0, 33% 0, 100% 100%, 0 100%)'],
      backgroundColor: ['', '', '', 'background'],
      color: 'text',
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoHero: {
      maxHeight: '250px',
      margin: '0 auto 1rem',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: ['1.5rem', '1.8rem', '2.5rem'],
      marginBottom: 3,
      maxWidth: ['', '', '70%'],
      whiteSpace: 'pre-wrap',
    },
    subtitle: {
      maxWidth: ['', '', '70%'],
      fontSize: ['1.2rem', '1.3rem', '1.5rem'],
      fontWeight: 'normal',
      marginBottom: 3,
      whiteSpace: 'pre-wrap',
    },
    text: {
      maxWidth: ['', '', '70%'],
      whiteSpace: 'pre-wrap',
      color: 'light',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      margin: '1rem 0',
      padding: '0.75rem 1.5rem',
      backgroundColor: 'primary',
      width: 'fit-content',
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================
    // the widget has the same variants as the hero so we can't not pass down a variant name
    shoutWidgetContainer: {
      '.shoutWidget': {
        width: '100%',
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        padding: '0rem',
        '.content': {
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: '1',
          paddingLeft: 2,
          paddingRight: 1,
          paddingY: 1,
          width: ['65%', '', '50%'],
          color: 'text',
        },
        '.title': {
          fontSize: 3,
          fontWeight: 'bold',
          color: 'secondary',
        },
        '.text': {
          fontSize: '0.8rem',
          marginY: 1,
        },
        '.date': {
          fontSize: 2,
          color: 'secondary',
          fontWeight: 'bold',
        },
        '.imageContainer': {
          width: ['35%', '', '50%'],
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
        '.shoutCTAS': {
          flexWrap: 'wrap',
          marginBottom: '0.5rem',
          a: {
            margin: '0.5rem 1rem 0.5rem 0rem',
            textDecoration: 'underline',
            fontSize: ['1.25rem', '1.5rem'],
            ':hover': {
              color: 'primary',
            },
          },
        },
      },
    },
  },
}
