const variantName = 'locationSwitcherSectionV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20vh 1rem',
      textAlign: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    section: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      marginBottom: '2rem',
      fontSize: '3rem',
    },
    subtitle: {
      marginBottom: '1rem',
    },
    text: {
      marginBottom: '1rem',
    },
  },
}
