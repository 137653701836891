export default {
  shoutSectionV2c: {
    container: {
      width: '100%',
      flexDirection: ['column', 'row'],
      fontSize: [4, 4, 5, 6],
      justifyContent: 'center',
    },
    content: {
      order: '2',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: ['flex-start', 'flex-start', ''],
      padding: '2rem 5rem 2rem 2rem',
      color: 'text',
      width: ['100%', '60%', '50%'],
      flexGrow: '1',
      position: 'relative',
    },
    title: {
      minHeight: '160px',
      fontSize: ['1.2rem', '', '1.4rem'],
      position: 'absolute',
      top: '0rem',
      right: '0rem',
      padding: '1rem',
      writingMode: 'vertical-lr',
      height: '100%',
      textAlign: 'left',
      color: 'white',
      width: '4rem',
      backgroundColor: 'secondary',
      whiteSpace: 'nowrap',
    },
    text: {
      fontSize: '1.75rem',
      width: '100%',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      margin: '1rem 0rem',
      letterSpacing: '-1px',
    },
    date: {
      margin: '0.5rem 0rem',
      fontSize: '0.75em',
      width: '100%',
      opacity: '0.5',
      textDecoration: 'underline',
    },
    imageContainer: {
      position: 'relative',
      width: ['100%', '40%', '50%'],
      flexGrow: '1',
      img: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    imageFill: {
      paddingBottom: '100%',
    },
    shoutCTAS: {
      flexWrap: 'wrap',
      marginBottom: '0.5rem',
      transition: 'all ease-in-out 0.3s',
    },
    shoutCTA: {
      variant: 'buttons.primary',
      margin: '0.5rem 1rem 0.5rem 0rem',
    },
  },
}
