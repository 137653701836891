export default {
  titleBlockV1b: {
    container: {
      textAlign: 'center',
      padding: '4rem 1rem',
      margin: '3rem 1rem 4rem',
      position: 'relative',
    },
    title: {
      maxWidth: '767px',
      fontSize: ['2rem', '', '3rem'],
      margin: '0 auto',
      ':before': {
        content: "''",
        width: '40%',
        left: '1rem',
        top: '0px',
        backgroundColor: '#2b2b2b',
        height: '2px',
        position: 'absolute',
      },
      ':after': {
        content: "''",
        width: '40%',
        backgroundColor: '#2b2b2b',
        height: '2px',
        right: '1rem',
        bottom: '0px',
        position: 'absolute',
      },
    },
  },
}
