export default {
  h1: {
    fontSize: '2rem',
    margin: '0rem 0rem 1rem',
    fontFamily: 'inherit',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '1.75rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '1.5rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '1.25rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold',
  },
  h5: {
    fontSize: '1rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold',
  },
  h6: {
    fontSize: '0.8rem',
    margin: '0rem 0rem 1rem',
    fontWeight: 'bold',
  },
  p: {
    margin: '0rem 0rem 1rem',
  },
  a: {
    fontWeight: 'bold',
  },
  ul: {
    margin: '0rem 0rem 1rem',
  },
  ol: {
    margin: '0rem 0rem 1rem',
  },
  li: {
    lineHeight: '1.5',
    marginBottom: '0.5rem',
  },
}
