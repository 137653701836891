// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-minimal-src-pages-404-js": () => import("./../../../../gatsby-theme-minimal/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-404-js" */),
  "component---gatsby-theme-minimal-src-pages-indexnoop-js": () => import("./../../../../gatsby-theme-minimal/src/pages/indexnoop.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-indexnoop-js" */),
  "component---gatsby-theme-minimal-src-templates-article-menu-template-js": () => import("./../../../../gatsby-theme-minimal/src/templates/ArticleMenuTemplate.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-article-menu-template-js" */),
  "component---gatsby-theme-minimal-src-templates-article-template-js": () => import("./../../../../gatsby-theme-minimal/src/templates/ArticleTemplate.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-article-template-js" */),
  "component---gatsby-theme-minimal-src-templates-page-template-js": () => import("./../../../../gatsby-theme-minimal/src/templates/PageTemplate.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-page-template-js" */)
}

