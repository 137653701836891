// ! Considering Removing This Feature
// ! multiple menu items Feature solves this components purpose

const variantName = 'galleryV2'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: '3rem 1rem',
      width: '100%',

      // ?== Nested Elements ==
      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-list': {
        width: '100vw',
      },
      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '50vh',
      },
      '.slick-slide img': {
        height: '50vh',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)',
      },
      '.slick-dots': {
        bottom: '25px',
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px',
      },
      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px',
      },
      '.slick-prev, .slick-next': {
        top: 'unset',
        left: 'unset',
        bottom: '20px',
        right: '25px',
      },
      '.slick-next': {
        zIndex: '500',
      },
      '.slick-prev': {
        right: '55px',
        zIndex: '500',
      },
    },
    wrapper: {
      width: '100%',
    },

    // ?============================
    // ?====  Albums Containers ====
    // ?============================

    albumsContainer: {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& > div:nth-child(even) img': {
        order: ['', '', '2'],
      },
    },
    albumSection: {
      width: ['100%'],
      padding: '0.5rem',
      cursor: 'pointer',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      flexDirection: ['column', '', 'row'],
      margin: ['1rem 0rem', '', '2rem'],
    },
    albumSectionImage: {
      objectFit: 'cover',
      height: ['300px', '', '400px'],
      width: '100%',
    },
    albumSectionContent: {
      flexDirection: 'column',
      justifyContent: ['', '', 'center'],
      textAlign: 'left',
      width: '100%',
      flexGrow: '1',
      padding: ['', '', '2rem'],
      'h3, h4': {
        marginBottom: '1rem',
      },
      '& :first-child': {
        marginTop: '1rem',
      },
      '& :last-child': {
        marginBottom: '0rem',
      },
    },
    albumSectionTitle: {
      color: 'inherit',
    },
    albumSectionSubtitle: {
      opacity: '0.5',
    },
    albumSectionDescription: {},
    albumSectionCTA: {
      // margin: 'auto 0 0 auto',
      margin: '1rem 0',
      width: 'fit-content',
    },

    // ?============================
    // ?=====  Album Container =====
    // ?============================
    // * The Album Page/ Portfolio page

    // albums
    albumContainer: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
      // flexDirection: 'column',
      margin: '1rem auto',
      justifyContent: 'center',
    },

    albumHeading: {
      margin: '2rem auto 1rem',
      maxWidth: '1000px',
      flexDirection: 'column',
    },

    albumContent: {
      flexDirection: 'column',
      maxWidth: '1000px',
      margin: '1rem auto',
    },
    albumTitle: { fontSize: ['1.5rem', '2rem', '2.5rem'] },
    albumSubtitle: { opacity: '0.5' },
    albumDescription: {},

    dataBoxes: {
      flexWrap: 'wrap',
      margin: '0.5rem 0 2rem',
      '& :last-child': {
        border: 'none',
      },
    },
    dataBox: {
      flexDirection: 'column',
      margin: '0.75rem 1rem 0.75rem 0rem',
      // borderRight: 'solid 1px',
      borderColor: 'text',
      padding: '0.5rem 1rem 0.5rem 0rem',
    },
    dataBoxTitle: {
      color: 'primary',
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    gallerySwitcher: {
      maxWidth: '767px',
      margin: '2rem auto',
      justifyContent: 'space-between',
    },
  },
}
