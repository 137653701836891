const variantName = 'galleryV4a'

export default {
  [variantName]: {
    container: {
      width: '100%',
      padding: '1rem 0rem',

      // slick styles
      // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
      '.slick-slider': {
        height: '400px',
        width: '100%',
      },

      '.slick-initialized .slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: '400px',
      },
      '.slick-slide img': {
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '0rem',
        objectFit: 'cover',
        width: '100%',
        transform: 'translate(-50%, -50%)',
      },
      '.slick-dots': {
        bottom: '-2rem',
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px',
      },
      '.slick-next, .slick-prev': {
        zIndex: '500',
        width: '30px',
        height: '30px',
        fontSize: '1rem',
      },
      '.slick-arrow': {
        bottom: 'unset',
        right: '2rem',
        top: '-2rem',
        color: 'primary',
        display: ['none !important', '', 'block !important', ''],
        border: 'solid 1px black',
      },

      '.slick-next': {
        right: '2rem',
      },
      '.slick-prev': {
        right: '4rem',
        left: 'unset',
      },
      '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus': {
        opacity: '0.7',
        color: 'inherit',
      },

      '.slick-dots li.slick-active button:before': {
        color: 'primary',
      },
      '.slick-dots li button:before': {
        fontSize: '10px',
        color: 'dark',
      },
    },

    wrapper: {
      width: '100%',
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    textContent: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1rem',
      textAlign: 'center',
      justifyContent: 'center',
    },

    // ?============================
    // ?====  Each Slide Style =====
    // ?============================

    slideBox: {
      flexDirection: 'column',
      display: 'flex !important', // slider package gives it a inline style of inline block
      margin: '0.5rem',
      width: 'calc(100% - 1rem)!important',
      height: '400px',
    },
    imageContainer: {
      position: 'relative',
    },

    // ?============================
    // ?===  Slide Text Content ====
    // ?============================
    title: { marginBottom: '0.5rem', fontSize: '1.5rem' },
    subtitle: { marginBottom: '0.5rem', fontSize: '1.1rem', opacity: '0.8' },
    text: { marginBottom: '0.5rem', fontSize: '1rem' },
  },
}
