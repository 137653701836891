export default {
  sideBySideV1b: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      '.lazyload-wrapper': {
        width: ['100%', '', '50%'],
      },
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', 8],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      order: ['', '', '2'],
      margin: ['', '', '4rem 0rem'],
      position: ['', '', 'relative'],
      backgroundColor: ['', '', 'background'],
      borderLeft: ['', '', 'solid 10px'],
      borderColor: ['', '', 'primary'],
      left: ['', '', '-3vw'],
      zIndex: '10',

      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3,
    },
    title: {
      fontSize: 6,
      marginBottom: 3,
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light',
      },
    },

    text: {
      lineHeight: '1.75',
      // whiteSpace: 'break-spaces',
    },

    image: {
      width: ['100%', '', '100%'],
      height: '100%',
      objectFit: 'cover',
    },
  },
}
