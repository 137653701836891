const variantName = 'heroV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    hero: {
      position: 'relative',
      flexDirection: 'column',
      height: '100vh',
      width: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.5rem',
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },

    // ?============================
    // ?======  Box Content ========
    // ?============================

    content: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // ?============================
    // ?=========  Logos  ==========
    // ?============================

    logoHero: {
      maxHeight: ['175px', '', '200px', '250px'],
      marginBottom: 3,
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================

    title: {
      fontSize: 8,
      marginBottom: 3,
    },
    subtitle: {
      fontSize: 5,
      fontWeight: 'normal',
      marginBottom: 3,
    },
    text: {},

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaButton: {
      marginTop: 3,
      backgroundColor: 'primary',
      padding: '0.75rem 1.5rem',
      display: 'flex',
      '&:hover': {
        backgroundColor: 'secondary',
      },
    },

    // ?============================
    // ?=======  Hero Shout  =======
    // ?============================

    shoutWidget: {
      container: {
        width: '100%',
        alignItems: 'stretch',
        backgroundColor: 'background',
        padding: 0,
        maxWidth: '500px',
        display: 'flex',
        bottom: '1rem',
        left: '1rem',
      },
      content: {
        order: '2',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: '1',
        paddingLeft: 2,
        paddingRight: 1,
        paddingY: 1,
        width: '80%',
        color: 'text',
      },
      title: {
        fontSize: 3,
        fontWeight: 'bold',
        color: 'secondary',
      },
      text: {
        fontSize: '0.8rem',
        marginY: 1,
      },
      date: {
        fontSize: 2,
        color: 'secondary',
        fontWeight: 'bold',
      },
      imageContainer: {
        order: '1',
        width: '35%',
        img: {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          border: 'solid 3px',
          borderColor: 'secondary',
        },
      },
    },
  },
}
