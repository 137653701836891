const variantName = 'multiLocationsV5'

export default {
  [variantName]: {
    container: {
      height: '100vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      position: 'relative',
      transition: 'all ease-in-out 0.5s',
      '::before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '95%',
        height: '95vh',
        transform: 'translate(-50%, -50%)',
        border: 'solid 1px white',
      },
    },
    logo: {
      maxWidth: '900px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // background: '#0000007a',
      width: '100%',
      padding: '1rem 2rem',
      img: {
        filter: 'brightness(0) invert(1)',
      },
    },
    locationsContainer: {
      position: 'absolute',
      bottom: '0%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      width: '100%',
      maxWidth: '600px',
    },
    location: {
      margin: '2rem',
      width: '50%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // background: '#ffffff7d',
      padding: '1rem',
    },
    content: {},
    locationTitle: {
      color: 'white',
      textAlign: 'center',
      transition: 'all ease-in-out 0.7s',
      borderWidth: '0px',
      borderColor: 'black',
      ':hover': {
        transform: 'scale(1.2)',
        borderBottom: 'black solid 3px',
      },
    },
    CTAS: {},
    CTAButton: {},
    enterSiteButton: {},
  },
}
