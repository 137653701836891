export default {
  sideBySideV1c: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['row', '', 'row'],
      alignItems: ['center', '', ''],
      maxWidth: '800px',
      margin: '1rem auto',
      '.lazyload-wrapper': {
        width: ['30%', '', '50%'],
        // display: 'flex',
        // alignItems: 'center',
      },
    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['70%', '', '50%'],
      justifyContent: 'center',
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3,
    },
    title: {
      fontSize: 6,
      marginBottom: 3,
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
      },
    },

    text: {
      lineHeight: '1.75',
      // whiteSpace: 'break-spaces',
    },
    image: {
      width: ['175%', '', '100%'],
      height: '100%',
      objectFit: 'cover',
      maxWidth: 'unset',
    },
  },
}
