export default {
  sideBySideV5: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],

    },
    content: {
      flexDirection: 'column',
      padding: [4, '', '', 8],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3,
    },
    title: {
      fontSize: 6,
      marginBottom: 3,
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light',
      },
    },

    text: {
      lineHeight: '1.75',
      whiteSpace: 'break-spaces',
    },
    image: {
      width: ['100%'],
      height: '100%',
      objectFit: 'cover',
    },
    videoContainer: {
    width: ['100%', '', '50%'],
    position:'relative',
    video: {
 
          width: "100%",
    objectFit: "cover",
    height: "100%",
    },
    },
    muteToggle: {
      position:'absolute',
      top:'0px',
      right:"0px",
      fontSize:'2rem',
    },
  },
}
