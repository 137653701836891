const variantName = 'locationBoxesV1'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================
    container: {
      justifyContent: 'center',
      alignItems: 'stretch',
      textAlign: 'center',
      flexWrap: 'wrap',
      padding: '2rem 1rem',
    },

    // ?============================
    // ?=== ContentBox/Location ====
    // ?============================
    box: {
      flexDirection: 'column',
      flexGrow: '1',
      padding: '1rem',
      a: {
        '&:hover': {
          color: 'primary',
          '&:hover svg path': {
            fill: 'primary',
          },
        },
        svg: {
          path: {
            fill: 'text',
          },
          '&:hover path': {
            fill: 'primary',
          },
        },
      },
    },

    // ?============================
    // ?=========  Widgets  ========
    // ?============================

    socialIconsContainer: {
      padding: '0.5rem',
      a: {
        svg: {
          width: '1.25rem',
          height: '1.25rem',
          margin: '0rem 0.5rem',
        },
      },
      justifyContent: 'center',
      '& > div': {
        height: 'unset',
        justifyContent: 'center',
      },
    },
  },
}
